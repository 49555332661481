import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logos/mm-txt.svg";

export default class LandingNav extends Component {
  render() {
    return (
      <nav
        className="navbar navbar-expand-sm navbar-light mb-4"
        // style={{ backgroundColor: "#4285F4" }}
        id="the-navbar"
      >
        <div className="container p-0">
          <Link className="navbar-brand opa-hover" to="/">
            <img
              src={logo}
              alt="Pyrls Logo"
              id="the-logo"
              style={{ maxHeight: "57px" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#mobile-nav"
            style={{ color: "#0865d1", border: "none" }}
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "#0865d1" }}
            />
          </button>

          <div className="collapse navbar-collapse" id="mobile-nav">
            <ul className="navbar-nav mr-auto" />
            <ul className="navbar-nav ml-auto">
              {/* <li className="nav-item mr-4">
                <Link className="nav-link pt-3" to="/dashboard">
                  Demo
                </Link>
              </li> */}
              {/* <li className="nav-item mr-4">
                <a
                  href=""
                  className="nav-link pt-3"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  text
                </a>
              </li> */}
              <li className="nav-item mr-4">
                <Link className="nav-link pt-2" to="/signup">
                  Sign Up
                </Link>
              </li>
              <li className="nav-item mr-4">
                <Link className="nav-link pt-2" to="/login">
                  Login
                </Link>
              </li>
              {/* <li className="nav-item">
                <a href="https://app.pyrls.com/login" className="nav-link">
                  <button className="main-pyrlple-btn">
                    <h6 className="m-1 ml-4 mr-4 font-weight-light">Login</h6>
                  </button>
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
