import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// Actions
import { logOut } from "../../store/actions/authActions";
import {
  subscribeYearly,
  subscribeForever
} from "../../store/actions/profileActions";
// Components
import Spinner from "../common/Spinner";
import Topnav from "../navs/Topnav";
import Checkout1 from "../payment/Checkout1";
import Checkout2 from "../payment/Checkout2";
import waves from "../../img/figures/wave.svg";
// IMG
import stripe from "../../img/logos/stripe.svg";

class Home extends Component {
  state = {
    display: ""
  };

  //// $12
  purchase1(token) {
    // Show please wait sign
    // document.getElementById('plz-wait').style.opacity = '1';
    // Begin charging Stripe

    // console.log(token)
    axios
      .post(
        "https://us-central1-medmorize-327b5.cloudfunctions.net/paymentOne",
        token
      )
      .then(res => {
        if (res.data.paid === true) {
          console.log("Payment Success!");
          this.setState({ display: "spinner" });
          this.props.subscribeYearly();
        } else if (res.data.paid === false) {
          console.log("Payment failure");
          // document.getElementById('plz-wait').innerHTML = 'Card Failure';
        } else {
          console.log("Something else");
        }
      });
  }
  /// $25
  purchase2(token) {
    // Show please wait sign
    // document.getElementById('plz-wait').style.opacity = '1';
    // Begin charging Stripe

    // console.log(token)
    axios
      .post(
        "https://us-central1-medmorize-327b5.cloudfunctions.net/paymentTwo",
        token
      )
      .then(res => {
        if (res.data.paid === true) {
          console.log("Payment Success!");
          this.setState({ display: "spinner" });
          this.props.subscribeForever();
        } else if (res.data.paid === false) {
          console.log("Payment failure");
          // document.getElementById('plz-wait').innerHTML = 'Card Failure';
        } else {
          console.log("Something else");
        }
      });
  }

  displaySpinner() {
    this.setState({ display: "spinner" });
  }

  render() {
    const { profile } = this.props;

    let displayContent;

    if (profile.name === undefined) {
      displayContent = <Spinner />;
    } else {
      if (profile.subscription.active === false) {
        displayContent = (
          <div
            className="container p-0"
            style={{
              maxWidth: "1000px",
              minHeight: "100vh"
            }}
          >
            <div className="row no-gutters mb-1">
              <div className="col-md-12">
                <Topnav />
              </div>
            </div>
            <div className="row no-gutters mb-5">
              <div className="col-md-4 m-auto text-center pb-5">
                <h3 className="light mb-5">
                  Welcome, <br /> {profile.name}
                </h3>
                <h1 className="boldest mt-4 text-bluegray">$12</h1>
                <h4 className="bold mb-4">12 Months. Full Access.</h4>
                <Checkout1
                  charge={this.purchase1.bind(this)}
                  spinner={this.displaySpinner.bind(this)}
                />
                <h1 className="boldest mt-5 text-bluegray">$25</h1>
                <h4 className="bold mb-4">Full Access. Forever.</h4>
                <Checkout2
                  charge={this.purchase2.bind(this)}
                  spinner={this.displaySpinner.bind(this)}
                />
                <div className="text-center mt-5 mb-4">
                  <p className="text-sm">Payment Secured By</p>
                  <a
                    href="https://stripe.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="opa-hover"
                  >
                    <img
                      src={stripe}
                      alt="Stripe logo"
                      style={{ width: "87px", opacity: "0.87" }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
        if (this.state.display === "spinner") {
          displayContent = <Spinner />;
        }
      } else {
        displayContent = (
          <div
            className="container-fluid p-0"
            style={{ backgroundColor: "", minHeight: "100vh" }}
          >
            <div
              className="container p-0"
              style={{
                maxWidth: "1000px",
                minHeight: "70vh"
              }}
            >
              <div className="row no-gutters mb-5">
                <div className="col-md-12">
                  <Topnav />
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-12">
                  <div className="spacer-block"></div>
                </div>
              </div>

              <div className="row no-gutters">
                <div className="col-md-4">
                  <Link
                    to="/bg"
                    className="product-card m-auto"
                    style={{ backgroundColor: "#5397fd" }}
                  >
                    <i className="fas fa-signature prodmain"></i>
                  </Link>
                  <p className="product-card-subtext">Brand-Generic</p>
                </div>
                <div className="col-md-4">
                  <Link
                    to="/class"
                    className="product-card m-auto"
                    style={{ backgroundColor: "#c35fff" }}
                  >
                    <i className="fas fa-sitemap prodmain"></i>
                  </Link>
                  <p className="product-card-subtext">Therapeutic Class</p>
                </div>
                <div className="col-md-4">
                  <Link
                    to="/mechanism"
                    className="product-card m-auto"
                    style={{ backgroundColor: "#9661e2" }}
                  >
                    <i className="fas fa-tools prodmain"></i>
                  </Link>
                  <p className="product-card-subtext">Mechanism of Action</p>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-md-12 text-center mt-5 mb-3 pr-5 pl-5">
                  Medmorize works perfect on your{" "}
                  <span className="boldest">mobile phone</span> screen!
                </div>
              </div>
            </div>
            <div className="row no-gutters p-0">
              <div className="col-md-12 p-0">
                <img src={waves} alt="" className="waves" />
              </div>
            </div>
            <div
              className="row no-gutters p-0"
              style={{
                minHeight: "20vh",
                backgroundColor: "#AECBFB"
              }}
            >
              <div className="col-md-6 m-auto pb-4">
                <div className="row no-gutters">
                  <div
                    className="col-md-3 m-auto text-center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <a
                      href="https://rxg777.typeform.com/to/Fplccg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <p className="mb-0 text-gray-light bold opa-hover">
                        Contact
                      </p>
                    </a>
                  </div>
                  <div
                    className="col-md-3 m-auto text-center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Link to="/disclaimer">
                      <p className="mb-0 text-gray-light bold opa-hover">
                        Disclaimer
                      </p>
                    </Link>
                  </div>
                  <div
                    className="col-md-3 m-auto text-center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Link to="/terms-of-service">
                      <p className="mb-0 text-gray-light bold opa-hover">
                        Terms of Service
                      </p>
                    </Link>
                  </div>
                  <div
                    className="col-md-3 m-auto text-center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                  >
                    <Link to="/privacy-policy">
                      <p className="mb-0 text-gray-light bold opa-hover">
                        Privacy Policy
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return displayContent;
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut()),
    subscribeYearly: () => dispatch(subscribeYearly()),
    subscribeForever: () => dispatch(subscribeForever())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
