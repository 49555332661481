import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logos/mm-logo.svg";
import circleLogo from "../../img/logos/circle-logo.svg";

export default class Topnav extends Component {
  render() {
    return (
      <nav
        className="navbar navbar-expand-sm navbar-light mb-2"
        // style={{ backgroundColor: "#4285F4" }}
        id="the-navbar"
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={logo}
              alt="RxGrad Logo"
              id="the-logo"
              className="opa-hover"
              style={{ maxHeight: "70px", maxWidth: "150px" }}
            />
          </Link>

          <ul className="navbar-nav text-right">
            <li className="nav-item mr-1">
              <Link className="nav-link text-right pt-0" to="/account">
                <i className="fas fa-cogs opa-hover dum"></i>
              </Link>
            </li>
          </ul>

          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#mobile-nav"
            style={{ color: "#0865d1" }}
          >
            <span
              className="navbar-toggler-icon"
              style={{ color: "#0865d1" }}
            />
          </button> */}

          {/* <div className="collapse navbar-collapse" id="mobile-nav">
            <ul className="navbar-nav mr-auto" />
            <ul className="navbar-nav ml-auto">
              <li className="nav-item mr-4">
                <Link className="nav-link" to="/account">
                  <i className="fas fa-cogs opa-hover dum"></i>
                </Link>
              </li>
         
            </ul>
          </div> */}
        </div>
      </nav>
    );
  }
}
