import React, { Component } from "react";
import { connect } from "react-redux";
import { login } from "../../store/actions/authActions";
import { Link } from "react-router-dom";

import logo from "../../img/logos/mm-txt.svg";
import spinner from "../../img/spinners/white-spinner.gif";

class Login extends Component {
  state = {
    email: "",
    password: ""
  };

  formType = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = e => {
    e.preventDefault();
    this.props.login(this.state);
  };

  componentDidUpdate() {
    if (this.props.authError) {
      document.getElementById("login-btn-text").style.display = "block";
      document.getElementById("login-spinner").style.display = "none";
    }
  }

  render() {
    return (
      <div className="container-fluid p-0 auth-background">
        <div className="row no-gutters">
          <div className="col-md-5 m-auto p-2" style={{ maxWidth: "370px" }}>
            <div className="text-center mt-5 mb-5"></div>
            <div
              className="auth-container mb-5"
              style={{ borderRadius: "27px" }}
            >
              <div className="text-center pt-3 pb-3">
                <Link to="/">
                  <img src={logo} alt="Medmorize logo" className="auth-logo" />
                </Link>
              </div>
              <div className="p-2 text-center">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group mb-4">
                    <input
                      type="text"
                      className="form-control input-3"
                      name="email"
                      placeholder="Email Address"
                      onChange={this.formType}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control input-3"
                      name="password"
                      autoComplete="current-password"
                      placeholder="Password"
                      onChange={this.formType}
                    />
                    <br />
                    {this.props.authError && (
                      <p className="text-danger">{this.props.authError}</p>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="content-container-5 grad-btn opa-hover"
                    style={{ borderRadius: "27px", padding: "7px 50px" }}
                    onClick={() => {
                      document.getElementById("login-btn-text").style.display =
                        "none";
                      document.getElementById("login-spinner").style.display =
                        "block";
                    }}
                  >
                    <span id="login-btn-text" className="text-white">
                      Submit
                    </span>
                    <img
                      src={spinner}
                      alt="spinner"
                      className="loading-spinner-1 m-auto"
                      id="login-spinner"
                    />
                  </button>
                  <br />
                  <Link
                    to="/password-reset"
                    className="text-bluegray text-sm mb-0 opa-hover"
                  >
                    Forgot Password
                  </Link>
                  <hr />
                  <p className="text-sm">Don't have an account?</p>
                  <Link to="/signup">
                    <button className="modal-link-btn">
                      <p className="m-1 mr-3 ml-3 text-white">Sign Up</p>
                    </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: credentials => dispatch(login(credentials))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
