////  LOGIN ////
export const login = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "LOGIN_ERROR" });
      });
  };
};

////  LOGOUT ////
export const logOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.href = "/login";
        dispatch({ type: "LOGOUT_SUCCESS" });
      });
  };
};

//// SIGN UP ////
export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        return firestore
          .collection("users")
          .doc(resp.user.uid)
          .set({
            name: newUser.name,
            email: newUser.email,
            account: {
              status: "active",
              agreedToTermsOfService: true,
              role: "user",
              created: firebase.firestore.FieldValue.serverTimestamp()
            },
            userInfo: {
              occupation: "",
              interests: "",
              status: "",
              jobTitle: "",
              company: "",
              degree: "",
              school: "",
              graduationYear: "",
              location: ""
            },
            subscription: {
              active: true
            },
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
          });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
        // document.getElementById("loading-spinner").style.opacity = 0;
      })
      .catch(err => {
        dispatch({ type: "SIGNUP_ERROR", err });
        if (document.getElementById("loading-spinner")) {
          //   document.getElementById("loading-spinner").style.opacity = 0;
        }
      });
  };
};

//// RESET PASSWORD ////
export const resetPassword = email => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function() {
        // Email sent
        dispatch({ type: "PASSWORD_RESET" });
      })
      .catch(function(err) {
        // An error happened
        console.log(err);
      });
  };
};

//// CHANGE EMAIL ////
export const changeEmail = credentials => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const credential = firebase.auth.EmailAuthProvider.credential(
      credentials.oldEmail,
      credentials.currentPassword
    );

    firebase
      .auth()
      .currentUser.reauthenticateAndRetrieveDataWithCredential(credential)
      .then(function() {
        firebase
          .auth()
          .currentUser.updateEmail(credentials.newEmail)
          .then(function() {
            // Update successful.
            var user = firebase.auth().currentUser;
            const profile = firestore.collection("users").doc(user.uid);
            profile
              .update({
                email: credentials.newEmail,
                lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
              })
              .then(() => {
                dispatch({ type: "CHANGE_EMAIL" });
              })
              .catch(err => console.log(err));
          })
          .catch(function(err) {
            dispatch({ type: "CHANGE_EMAIL_ERROR", err });
          });
      })
      .catch(function(err) {
        if (err.code === "auth/wrong-password") {
          err.message = "Invalid password";
        }
        dispatch({ type: "CHANGE_EMAIL_ERROR", err });
      });
  };
};
//// CHANGE PASSWORD ////
export const changePassword = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const credential = firebase.auth.EmailAuthProvider.credential(
      credentials.email,
      credentials.oldPassword
    );

    firebase
      .auth()
      .currentUser.reauthenticateAndRetrieveDataWithCredential(credential)
      .then(function() {
        firebase
          .auth()
          .currentUser.updatePassword(credentials.newPassword)
          .then(function() {
            // Update successful.
            dispatch({ type: "CHANGE_PASSWORD" });
          })
          .catch(function(err) {
            console.log(err);
            if (err.code === "auth/wrong-password") {
              err.message = "Invalid password";
            }
            dispatch({ type: "CHANGE_PASSWORD_ERROR", err });
          });
      })
      .catch(function(err) {
        console.log(err);
        if (err.code === "auth/wrong-password") {
          err.message = "Invalid password";
        }
        dispatch({ type: "CHANGE_PASSWORD_ERROR", err });
      });
  };
};
//// DELETE ACCOUNT ////
export const deleteAccount = credentials => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const credential = firebase.auth.EmailAuthProvider.credential(
      credentials.email,
      credentials.currentPassword
    );

    firebase
      .auth()
      .currentUser.reauthenticateAndRetrieveDataWithCredential(credential)
      .then(function() {
        // Update account to deleted
        var user = firebase.auth().currentUser;
        const profile = firestore.collection("users").doc(user.uid);
        profile
          .update({
            "account.status": "deleted",
            "account.deleted": firebase.firestore.FieldValue.serverTimestamp()
          })
          .then(() => {
            firebase
              .auth()
              .currentUser.delete()
              .then(function() {
                // Update successful.
                dispatch({ type: "DELETE_ACCOUNT" });
              })
              .catch(function(err) {
                console.log(err);
              });
          })
          .catch(err => console.log(err));
      })
      .catch(function(err) {
        console.log(err);
        if (err.code === "auth/wrong-password") {
          err.message = "Invalid password";
        }
        dispatch({ type: "DELETE_ACCOUNT_ERROR", err });
      });
  };
};
