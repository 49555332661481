import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logos/mm-txt.svg";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <div className="container-fluid p-0 auth-background">
        <div className="row no-gutters">
          <div className="col-lg-6 m-auto p-2">
            <div className="text-center mt-5 mb-5"></div>
            <div className="auth-container mb-5">
              <div className="text-center pt-3 pb-3">
                <Link to="/">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ width: "200px" }}
                    className="opa-hover"
                  />
                </Link>
                <h4 className="text-pyrlgray-light mb-4 mt-4 boldest">
                  Privacy Policy
                </h4>
                <hr />
                <div className="text-left p-3">
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'italic', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Last Modified/Effective Date: September 20th, 2019</span></p>
  <p>
    <br />
  </p>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>By continuing use of this site and sharing your information with us, you agree to the terms of this Privacy Policy and our Terms and Conditions of Use.</span></p>
  <p>
    <br />
  </p>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>We are Medmorize.co, and we appreciate your trust in our products and services. In order to provide you with the best learning materials, we collect certain personal information from you. This Privacy Policy (“Policy”) outlines how we use this collected personal information.&nbsp;</span></p>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Scope and Overview</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>This Policy applies solely to information collected by this website, Medmorize.co. This Policy will describe the following:</span></p>
  <ol style={{marginTop: 0, marginBottom: 0}}>
    <li dir="ltr" style={{listStyleType: 'decimal', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>What personal information is collected from you through Medmorize.co.</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'decimal', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>What choices are available to you regarding the collection and use of your information.</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'decimal', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>The security procedures in place to protect your information.</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'decimal', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>How you can correct any inaccuracies in your information.</span></p>
    </li>
  </ol>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Information Collection, Use and Sharing</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Medmorize.co collects information from the following sources:</span></p>
  <ul style={{marginTop: 0, marginBottom: 0}}>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Information you provide to us voluntarily via registration on this site, email, or other direct contact with us; and</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Cookies and/or similar technologies used on this website, which collect aggregate user data regarding use of and activity on this website.</span></p>
    </li>
  </ul>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>We are the sole owners of the information we collect.</span></p>
  <p>
    <br />
  </p>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>This information is used:</span></p>
  <ul style={{marginTop: 0, marginBottom: 0}}>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>To provide the service or product you have requested;</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>To enable billing and shipping;</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>To provide you with customer service and respond to your communications with us;</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>To communicate with you regarding specials, new products or services, or changes to this Policy, unless you have opted out of such communications;</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>To communicate with educational facilities and licensing boards, such as to report your test bank scores with your school faculty, unless you have opted out of such reporting;</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>To personalize our service to meet your needs;</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>For the development of new products and services; and</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>For systems administration and troubleshooting purposes.</span></p>
    </li>
  </ul>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Your information will only be shared with third parties outside of our organization when such sharing is necessary to:</span></p>
  <ul style={{marginTop: 0, marginBottom: 0}}>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Provide our services to you (e.g., sharing your mailing address with a shipping provider to send you the products you have ordered, or with a credit card processor for billing purposes);</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Communicate with educational facilities or licensing boards, including communications about misconduct or violations of our Terms and Conditions of Use, as outlined in those Terms;</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Comply with applicable laws, regulations, legal processes, or law enforcement requests;</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Exercise, establish, or defend our legal rights;</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Protect the rights, property, or safety of any person; or</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Complete any transactions involved with the sale of our business or assets.</span></p>
    </li>
  </ul>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Your Access to and Control Over Your Information</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>You can always review, correct, update, or request the deletion of any of your personal information that we have collected. You may also opt out of any future communications from us at any time.</span></p>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Specifically, you can do the following at any time by contacting us via email at hello@Medmorize.co:</span></p>
  <ul style={{marginTop: 0, marginBottom: 0}}>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Remove consent where you have provided it.</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Ask us to confirm if we are processing your information.</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Review what information we have collected about you, if any.</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Ask us to change or correct any information we have about you.</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Ask us to restrict how we are using your information (e.g., requesting to opt out of future communications).</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Ask us to delete your information.</span></p>
    </li>
    <li dir="ltr" style={{listStyleType: 'disc', fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre', marginLeft: '-11pt'}}>
      <p dir="ltr" style={{lineHeight: '1.38', marginRight: '-11pt', marginTop: '0pt', marginBottom: '8pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Express any concerns you may have about our use of your information.</span></p>
    </li>
  </ul>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>How We Protect Your Information</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Medmorize.co takes a range of security measures designed to protect your personal information, including by maintaining appropriate administrative, technical, and physical safeguards. However, the Internet is by its nature not a secure environment, and we cannot fully eliminate all security risks associated with sharing your personal information. As such, we do not ensure or warrant the security of any data or information you transmit to us. We encourage you to always be careful when sharing your information online.</span></p>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>California Privacy Rights</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>California law permits our customers who are California residents to request certain information regarding our disclosure of their personal information to third parties for their direct marketing purposes.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>We do not share our customer’s personal information with unaffiliated third parties for their own direct marketing purposes.&nbsp;</span><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>For inquiries regarding our disclosure policy, please send us an email to hello@Medmorize.co.</span></p>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Do Not Track</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>California law permits our customers who are California residents to be informed as to how we respond to Web browser “Do-Not-Track” settings. As “Do-Not-Track” is a standard that is currently being developed, we do not take actions to respond to “Do-Not-Track” settings or similar mechanisms at this time. Instead, we adhere to the standards set out in this Privacy Policy.</span></p>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Children’s Privacy</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Medmorize.co’s website is not directed towards children, and we do not knowingly collect any personal information from children. If we become aware that we have inadvertently received personal information from a user who is under the age of thirteen, we will delete that information.</span></p>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Links to Other Sites</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>While using the products services we provide to you, you may find links to other websites. These sites may be operated by third party organizations, who are not affiliated with Medmorize.co. We cannot control the content on these websites, and we are not responsible for the content, operation, or privacy practices of those sites. The privacy and security of the information you disclose to these third parties through these third-party sites are governed by those organizations’ privacy and security practices and policies. This Policy solely governs Medmorize.co, and does not apply to any outside website or service. You may choose to not access these third-party websites, and we encourage you to read any privacy notices or policies that may apply to those sites.</span></p>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>Changes to This Policy</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>This Policy may be changed from time to time. If we make any material changes to this Policy, we will highlight those changes within the new Policy. The date the Policy was last revised will always be posted at the top of the Policy.</span></p>
  <h5 dir="ltr" style={{lineHeight: '1.32', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '8pt', marginBottom: '8pt'}}><span style={{fontSize: '13.5pt', fontFamily: 'Arial', color: '#422b6f', backgroundColor: 'transparent', fontWeight: 700, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>How to Contact Us</span></h5>
  <p dir="ltr" style={{lineHeight: '1.38', marginLeft: '-11pt', marginRight: '-11pt', marginTop: '0pt', marginBottom: '0pt'}}><span style={{fontSize: '10.5pt', fontFamily: 'Arial', color: '#434343', backgroundColor: 'transparent', fontWeight: 400, fontStyle: 'normal', fontVariant: 'normal', textDecoration: 'none', verticalAlign: 'baseline', whiteSpace: 'pre-wrap'}}>If you have any questions or concerns about this Policy, please send our Privacy Team an email at hello@Medmorize.co.</span></p>
</div>

                <Link to="/">
                  <button className="modal-link-btn">
                    <p className="m-1 mr-3 ml-3 text-white">Home</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
