import React, { Component } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { compose } from "redux";

// Components
import Home from "./components/home/Home";
import ScrollToTop from "./components/home/ScrollToTop";
import Login from "./components/auth/Login";
import Signup from "./components/auth/Signup";
import NotFound from "./components/home/NotFound";
import Account from "./components/account/Account";
import Design from "./components/design/Design";
import PasswordReset from "./components/auth/PasswordReset";

import BrandGeneric from "./components/quizzes/BrandGeneric";
import Mechanism from "./components/quizzes/Mechanism";
import TherapeuticClass from "./components/quizzes/TherapeuticClass";
import Landing from "./components/landing/Landing";
import Disclaimer from "./components/landing/Disclaimer";
import PrivacyPolicy from "./components/landing/PrivacyPolicy";
import TermsOfService from "./components/landing/TermsOfService";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div className="App">
            <Switch>
              <Route
                path="/login"
                component={() =>
                  this.props.auth.uid ? <Redirect to="/home" /> : <Login />
                }
              />
              <Route
                path="/signup"
                component={() =>
                  this.props.auth.uid ? <Redirect to="/home" /> : <Signup />
                }
              />
              <Route
                exact
                path="/"
                component={() =>
                  this.props.auth.uid ? <Redirect to="/home" /> : <Landing />
                }
              />
              <Route
                path="/home"
                component={() =>
                  this.props.auth.uid ? <Home /> : <Redirect to="/login" />
                }
              />
              <Route
                path="/bg"
                component={() =>
                  this.props.auth.uid ? (
                    <BrandGeneric />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                path="/mechanism"
                component={() =>
                  this.props.auth.uid ? <Mechanism /> : <Redirect to="/login" />
                }
              />
              <Route
                path="/class"
                component={() =>
                  this.props.auth.uid ? (
                    <TherapeuticClass />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
              <Route
                path="/account"
                component={() =>
                  this.props.auth.uid ? <Account /> : <Redirect to="/login" />
                }
              />
              <Route path="/disclaimer" component={Disclaimer} />
              <Route path="/terms-of-service" component={TermsOfService} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/design" component={Design} />
              <Route path="/password-reset" component={PasswordReset} />
              {/* <Route path="/drug/:name" component={Drug} /> */}
              {/* <Route path="/favorites" component={Favorites} /> */}
              <Route component={NotFound} />
            </Switch>
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
    // profile: state.firebase.profile
  };
};

export default compose(
  connect(mapStateToProps)
  // firestoreConnect([{ collection: "projects" }])
)(App);
