import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logos/mm-txt.svg";

export default class TermsOfService extends Component {
  render() {
    return (
      <div className="container-fluid p-0 auth-background">
        <div className="row no-gutters">
          <div className="col-lg-6 m-auto p-2">
            <div className="text-center mt-5 mb-5"></div>
            <div className="auth-container mb-5">
              <div className="text-center pt-3 pb-3">
                <Link to="/">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ width: "200px" }}
                    className="opa-hover"
                  />
                </Link>
                <h4 className="text-pyrlgray-light mb-4 mt-4 boldest">
                  Terms of Service
                </h4>
                <hr />
                <div className="text-left p-3">
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.38",
                      marginLeft: "-11pt",
                      marginRight: "-11pt",
                      marginTop: "0pt",
                      marginBottom: "0pt"
                    }}
                  >
                    <span
                      style={{
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      These Terms of Service (“Terms”) are a contract between
                      you and &nbsp;Cosmas Health LLC which governs your access
                      to and use of any Medmorize website, content, products
                      and/or services made available through Medmorize
                      (collectively, the “Site”). Cosmas Health LLC.
                      ("Medmorize", "we" or "us") is the owner of the Medmorize
                      Site. The Medmorize Site contains information, including
                      without limitation, all text, graphics, photographs,
                      graphs, sounds, data, images, audio, video, page headers,
                      software (including HTML and other scripts), buttons and
                      other icons, and the arrangement and compilation of this
                      information (collectively, the "Information") that is
                      either owned or licensed by Medmorize. Please read these
                      Terms carefully before accessing and using the Site.
                    </span>
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol style={{ marginTop: 0, marginBottom: 0 }}>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Terms of Use.
                        </span>
                      </p>
                      <ol style={{ marginTop: 0, marginBottom: 0 }}>
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: "decimal",
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre"
                          }}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: "1.7999999999999998",
                              marginTop: "0pt",
                              marginBottom: "0pt"
                            }}
                          >
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              Acceptance of Terms.
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              &nbsp;By accessing and/or using the Site, you
                              accept and agree to be bound by these Terms, just
                              as if you had agreed to these Terms in writing.
                            </span>
                          </p>
                        </li>
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: "decimal",
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre"
                          }}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: "1.7999999999999998",
                              marginTop: "0pt",
                              marginBottom: "0pt"
                            }}
                          >
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              If you do not agree to these Terms, do not use the
                              Site.
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              <br />
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "underline",
                                WebkitTextDecorationSkip: "none",
                                textDecorationSkipInk: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              <br />
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              Amendment of Terms.
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              &nbsp;Medmorize may amend the Terms from time to
                              time. Unless Medmorize provides a delayed
                              effective date, all amendments will be effective
                              upon posting of such updated Terms. Your continued
                              access to or use of the Site after such posting
                              constitutes your consent to be bound by the Terms,
                              as amended.
                            </span>
                          </p>
                        </li>
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: "decimal",
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre"
                          }}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: "1.7999999999999998",
                              marginTop: "0pt",
                              marginBottom: "0pt"
                            }}
                          >
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              <br />
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              Additional Terms.
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              &nbsp;In addition to these Terms, when using
                              particular plans, offers, products, services or
                              features, you will also be subject to any
                              additional posted guidelines, or rules applicable
                              to such plan, offer, product, service or feature,
                              which may be posted and modified from time to time
                              (“Additional Terms”). All such Additional Terms
                              are hereby incorporated by reference into the
                              Terms, provided that in the event of any conflict
                              between such Additional Terms and the Terms, the
                              Terms shall control.
                            </span>
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          License.
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          &nbsp;Medmorize grants you a non-exclusive and
                          non-transferable license to use the Medmorize Site.
                          You may not download to hard copy individual pages of
                          the site for your personal reference. The content is
                          available on portable devices for&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "underline",
                            WebkitTextDecorationSkip: "none",
                            textDecorationSkipInk: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          viewing and test taking purposes only
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          . The Information contained on the Site may not be
                          transferred to, shared with or disseminated with
                          anyone for any purpose, including for personal profit,
                          to facilitate unfair competition with the Medmorize
                          Site, or for any purpose that is inappropriate or
                          unlawful under applicable United States or
                          international law or otherwise in violation of these
                          Terms. Medmorize reserves all rights, including but
                          not limited to, intellectual property rights not
                          expressly granted to you. The license is for your
                          individual use only and cannot be sold or transferred
                          unless separately, and explicitly authorized in
                          writing by Medmorize. Further, you may not rent,
                          lease, sublicense, distribute, transfer, copy,
                          reproduce, publicly display, publish, adapt, modify,
                          create derivative works, store or time-share the
                          Medmorize Site, any part thereof, or any of the
                          Information received or accessed therefrom, to or
                          through any other person or entity.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Restrictions.
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          &nbsp;Notwithstanding the foregoing, you may not
                          resell, redistribute, broadcast or transfer the
                          Information, or use the Information in a searchable,
                          machine-readable database or file except through
                          authorized access to the Medmorize Site. Unauthorized
                          access to the Site is strictly prohibited. You agree
                          to use the Site and Information for lawful purposes
                          only. You agree not to post or transmit any
                          information through the Medmorize Site which (i)
                          infringes the rights of others or violates their
                          privacy or publicity rights, (ii) is unlawful,
                          threatening, abusive, defamatory, libelous, vulgar,
                          obscene, profane, indecent or otherwise objectionable,
                          (iii) is protected by copyright, trademark or other
                          proprietary right without the express written
                          permission of the owner of such right, (iv) contains
                          unauthorized or malicious software such as viruses.
                          You shall be solely liable for any damages resulting
                          from your infringement of any copyright, trademark or
                          other proprietary right, or any other harm caused by
                          your use of the Medmorize Site or Information.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          User Security Obligations.
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          &nbsp;You agree to make reasonable efforts to ensure
                          that no one other than you, (the “Authorized User”)
                          obtains access, electronically or otherwise, to this
                          site with your login credentials. In the event that
                          you have reason to believe that someone has obtained
                          unauthorized access to this site, you agree (i) to
                          notify Medmorize at hello@Medmorize.co within 24 hours
                          of all information in your possession regarding such
                          potential unauthorized access, (ii) to take reasonable
                          action to resolve the unauthorized access, and (iii)
                          to cooperate with Medmorize in eliminating the
                          unauthorized access. Your liability for unauthorized
                          access is limited so long as you, the Authorized User,
                          has not willfully breached the limitations on your
                          license.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "8pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Termination.
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          &nbsp;Subject to the foregoing, your license to use
                          the Site will continue for the term of your
                          subscription, provided that you have complied with
                          these Terms. Note that access expires when the day
                          begins; for example, if access expires on March 15,
                          2019 the access ends after midnight on March 14, 2019.
                          Medmorize reserves the right to terminate, restrict,
                          or suspend your access to the Site at its discretion
                          upon written notice, as provided herein. Medmorize
                          reserves the right, at its sole discretion to tender a
                          prorated refund of any fees paid for the license. Upon
                          any termination, Medmorize may immediately deactivate
                          your account and all related information and/or bar
                          any further access to your account information and the
                          Site.
                        </span>
                      </p>
                    </li>
                  </ol>
                  <p
                    dir="ltr"
                    style={{
                      lineHeight: "1.7999999999999998",
                      marginLeft: "36pt",
                      marginTop: "0pt",
                      marginBottom: "8pt"
                    }}
                  >
                    <span
                      style={{
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      <br />
                    </span>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 700,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre-wrap"
                      }}
                    >
                      Eligibility; Registration Information and Password; Site
                      Access.
                    </span>
                  </p>
                  <ol start={6} style={{ marginTop: 0, marginBottom: 0 }}>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                          <br />
                        </span>
                      </p>
                      <ol style={{ marginTop: 0, marginBottom: 0 }}>
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: "decimal",
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre"
                          }}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: "1.7999999999999998",
                              marginTop: "0pt",
                              marginBottom: "0pt"
                            }}
                          >
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              Eligibility Criteria.
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              &nbsp;The availability of all or part of our Site
                              may be limited based on geographic location, or
                              other criteria as Medmorize may establish from
                              time to time. You understand and agree Medmorize
                              may disallow you from subscribing to Medmorize or
                              may terminate your subscription at any time based
                              on these criteria. For example, you must be 18
                              years of age or older to use this Site or to
                              purchase an Medmorize subscription. BY USING THE
                              SITE, YOU REPRESENT THAT YOU ARE A RESIDENT OF THE
                              UNITED STATES, THAT YOU ARE AT LEAST 18 YEARS OLD.
                              THOSE WHO CHOOSE TO ACCESS THE SITE DO SO AT THEIR
                              OWN INITIATIVE AND ARE RESPONSIBLE FOR COMPLIANCE
                              WITH ALL LOCAL RULES INCLUDING WITHOUT LIMITATION,
                              RULES ABOUT THE INTERNET, DATA, EMAIL OR OTHER
                              ELECTRONIC MESSAGES, OR PRIVACY.
                            </span>
                          </p>
                        </li>
                        <li
                          dir="ltr"
                          style={{
                            listStyleType: "decimal",
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre"
                          }}
                        >
                          <p
                            dir="ltr"
                            style={{
                              lineHeight: "1.7999999999999998",
                              marginTop: "0pt",
                              marginBottom: "0pt"
                            }}
                          >
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              <br />
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 700,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              Account Information.
                            </span>
                            <span
                              style={{
                                fontSize: "10.5pt",
                                fontFamily: "Arial",
                                color: "#434343",
                                backgroundColor: "transparent",
                                fontWeight: 400,
                                fontStyle: "normal",
                                fontVariant: "normal",
                                textDecoration: "none",
                                verticalAlign: "baseline",
                                whiteSpace: "pre-wrap"
                              }}
                            >
                              &nbsp;You agree that the information you provide
                              to Medmorize at registration and at all other
                              times will be true, accurate, current, and
                              complete. You also agree that you will ensure that
                              this information is kept accurate and up-to-date
                              at all times. When you register, you will be asked
                              to create a password. You are solely responsible
                              for maintaining the confidentiality of your
                              account and password, and for restricting access
                              to your computer, and you agree to accept
                              responsibility for all activities that occur under
                              your account.
                            </span>
                          </p>
                        </li>
                      </ol>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Ownership; Proprietary Rights.
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          &nbsp;The Medmorize website is owned and operated by
                          Cosmas Health LLC. The visual interfaces, graphics,
                          design, compilation, information, computer code,
                          products, software (including any downloadable
                          software), services, and all other elements of the
                          Site provided by Medmorize (“Materials”) are protected
                          by the copyright, trade dress, patent, and trademark
                          laws of the United States and other countries,
                          international conventions, and all other relevant
                          intellectual property and proprietary rights, and
                          applicable laws. All Materials contained on the Site
                          are the copyrighted property of Cosmas Health LLC or
                          its subsidiaries or affiliated companies and/or
                          third-party licensors. All trademarks, service marks,
                          and trade names are proprietary to Cosmas Health LLC
                          or its affiliates and/or third-party licensors. Except
                          as expressly authorized by Cosmas Health LLC, you
                          agree not to sell, license, distribute, copy, modify,
                          publicly perform or display, transmit, publish, edit,
                          adapt, create derivative works from, or otherwise make
                          unauthorized use of the Materials.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Notice.
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          &nbsp;Except as explicitly stated otherwise, legal
                          notices will be served, with respect to Medmorize, on
                          Medmorize’s registered agent, and, with respect to
                          you, to the email address you provide to Medmorize
                          during the registration process. Notice will be deemed
                          given twenty-four (24) hours after email is sent,
                          unless the sending party is notified that the email
                          address is invalid. Alternatively, Medmorize may give
                          you legal notice by mail to the address provided
                          during the registration process. In such case, notice
                          will be deemed given three (3) days after the date of
                          mailing.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Copyright, Patent and Trademark Notice.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Copyright 2019 Cosmas Health LLC. All rights reserved.
                          The Medmorize Site and Information is the valuable,
                          exclusive property of Medmorize or its licensors and
                          nothing in these Terms and Conditions shall be
                          construed as transferring or assigning any such
                          ownership rights to you or any other person or entity.
                          The Information is protected by contract law and
                          various intellectual property laws, including domestic
                          and international copyright laws. Except as expressly
                          permitted in these Terms and Conditions, you may not
                          copy, adapt, distribute, commercially exploit, or
                          publicly display the Information or any portion
                          thereof in any manner whatsoever without Medmorize's
                          prior written consent. You may not remove, alter or
                          obscure any copyright, legal or proprietary notices in
                          or on any portions of the Information. Medmorize, and
                          its associated logos, and all page headers, custom
                          graphics, buttons, and other icons are service marks,
                          trademarks, registered service marks, or registered
                          trademarks of Medmorize, Inc. All other product names
                          and company logos mentioned on the Medmorize Site or
                          Information are trademarks of their respective owners.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Electronic Signatures and Agreements.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          You acknowledge and agree that by clicking on the
                          button labeled “CONFIRM PURCHASE,” "SUBMIT",
                          "DOWNLOAD", “PLACE MY ORDER”, "I ACCEPT" or such
                          similar links as may be designated by Medmorize to
                          accept the terms and conditions of these Terms, you
                          are submitting a legally binding electronic signature
                          and are entering into a legally binding contract. You
                          acknowledge that your electronic submissions
                          constitute your agreement and intent to be bound by
                          these Terms. Pursuant to any applicable statutes,
                          regulations, rules, ordinances or other laws,
                          including without limitation the United States
                          Electronic Signatures in Global and National Commerce
                          Act, P.L. 106-229 (the "E-Sign Act") or other similar
                          statutes, YOU HEREBY AGREE TO THE USE OF ELECTRONIC
                          SIGNATURES, CONTRACTS, ORDERS AND OTHER RECORDS AND TO
                          ELECTRONIC DELIVERY OF NOTICES, POLICIES AND RECORDS
                          OF TRANSACTIONS INITIATED OR COMPLETED THROUGH THE
                          SITE OR SERVICES OFFERED BY Medmorize. Further, you
                          hereby waive any rights or requirements under any
                          statutes, regulations, rules, ordinances or other laws
                          in any jurisdiction which require an original
                          signature or delivery or retention of non-electronic
                          records, or to payments or the granting of credits by
                          other than electronic means.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Disclaimers.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          To the fullest extent permissible pursuant to
                          applicable law, your use of Cosmas Health LLC products
                          and services is solely at your own risk. In no event
                          shall Cosmas Health LLC be liable for any act, error,
                          or omission by you, including without limitation, any
                          which arises out of or is in any way connected to a
                          User’s use of a video, service or product made
                          available through the Site. You acknowledge and agree
                          that no warranties of any kind are made with respect
                          to the Medmorize Site, Medmorize App, and other sites.
                          Furthermore, you acknowledge that the Information and
                          links provided through the Medmorize Site are compiled
                          from sources that are generally beyond the control of
                          Cosmas Health LLC. Though such Information is
                          recognized to be generally reliable, you acknowledge
                          that inaccuracies may occur, and that Cosmas Health
                          LLC and its licensors do not warrant the accuracy or
                          suitability of the Information.
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          FOR THIS REASON, YOU ACKNOWLEDGE THAT THE MEDMORIZE
                          SITE AND INFORMATION ARE PROVIDED TO YOU ON AN "AS IS,
                          WITH ALL FAULTS" BASIS. COSMAS HEALTH LLC DOES NOT
                          WARRANT THAT THE MEDMORIZE SITE WILL MEET YOUR
                          REQUIREMENTS, WILL BE ACCURATE, OR WILL BE
                          UNINTERRUPTED OR ERROR FREE. COSMAS HEALTH LLC
                          EXPRESSLY EXCLUDES AND DISCLAIMS ALL EXPRESS AND
                          IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION,
                          WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
                          PARTICULAR PURPOSE. Medmorize SHALL NOT BE RESPONSIBLE
                          FOR ANY DAMAGE OR LOSS OF ANY KIND ARISING OUT OF OR
                          RELATED TO YOUR USE OF THE MEDMORIZE SITE AND
                          MEDMORIZE APP, AND/OR MEDMORIZE INFORMATION, INCLUDING
                          WITHOUT LIMITATION, DATA LOSS OR CORRUPTION,
                          REGARDLESS OF WHETHER SUCH LIABILITY IS BASED IN TORT,
                          CONTRACT OR OTHERWISE. UNDER THESE TERMS AND
                          CONDITIONS, YOU ASSUME ALL RISK OF ERRORS AND/OR
                          OMISSIONS IN THE Medmorize SITE AND INFORMATION,
                          INCLUDING THE TRANSMISSION OR TRANSLATION OF
                          INFORMATION. YOU HEREBY ASSUME ALL RESPONSIBILITY (AND
                          THEREBY HOLD COSMAS HEALTH LLC HARMLESS), BY WHATEVER
                          MEANS YOU DEEM MOST APPROPRIATE FOR YOUR NEEDS, FOR
                          DETECTING AND ERADICATING ANY VIRUS OR PROGRAM WITH A
                          SIMILAR FUNCTION.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Warranties.
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          &nbsp;The Site and any downloadable software, content,
                          services, or applications made in conjunction with
                          certain state laws do not allow limitations on implied
                          warranties or the exclusion or limitation of certain
                          damages. If these laws apply to you, some or all of
                          the disclaimers, exclusions, or limitations set forth
                          in these terms might not apply to you, and you may
                          have additional rights.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Release.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          By signing up for a Medmorize subscription, you
                          acknowledge and agree to release and discharge
                          Medmorize, its directors, officers, managers, members,
                          agents, employees, representatives and instructors,
                          (hereinafter referred to collectively as the “Released
                          Parties”), from any and all liability, claims,
                          demands, causes of action, and expenses, (including
                          attorneys’ fees and costs), whether past, present, or
                          future, for personal injury, death, and/or property
                          damage from any cause whatsoever arising from or in
                          connection with your use of the Medmorize platform,
                          even if such injury, death, and/or property damage is
                          caused by a negligent act or omission by Released
                          Parties.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Indemnification.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          You agree to defend, indemnify and hold Cosmas Health
                          LLC harmless from any claims, losses, damages,
                          liabilities, including attorneys’ fees, arising out of
                          your misuse of the Site, violation of these Terms,
                          violation of the rights of any other person or entity,
                          or any breach of your representations, warranties, and
                          covenants set forth in these Terms. To the fullest
                          extent permitted by law, you release, indemnify, and
                          hold harmless Cosmas Health LLC, its parent,
                          subsidiaries or affiliated entities, and each of their
                          respective officers, directors, members, employees,
                          consultants, contract employees, representatives and
                          agents, and each of their respective successors and
                          assigns, from any and all responsibility, claims,
                          actions, suits, procedures, costs, expenses, damages,
                          and liabilities arising out of or in any way related
                          to your participation in or use of your Medmorize
                          subscription or the Site, including without
                          limitation, your violation of these Terms and
                          Conditions, in each case whether or not caused by the
                          negligence of Cosmas Health LLC or its employees,
                          agents, licensors or contractors and whether or not
                          the relevant claim has merit. In the event that any
                          third-party claim is brought,Cosmas Health LLC has the
                          right and option to, at its own expense, undertake the
                          defense and control of such action with counsel of its
                          choice. If Cosmas Health LLC exercised this option,
                          you agree to cooperate with it in asserting any
                          available defenses.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Limitation of Liability and Damages.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          UNDER NO CIRCUMSTANCES WILL COSMAS HEALTH LLC OR ITS
                          AFFILIATES, CONTRACTORS, EMPLOYEES, AGENTS, OR
                          THIRD-PARTY PARTNERS BE LIABLE FOR ANY SPECIAL,
                          INDIRECT, INCIDENTAL, OR CONSEQUENTIAL DAMAGES UNDER
                          ANY THEORY OF LIABILITY, WHETHER BASED IN CONTRACT,
                          TORT (INCLUDING NEGLIGENCE AND PRODUCT LIABILITY), OR
                          OTHERWISE, EVEN IF Medmorize HAS BEEN ADVISED OF THE
                          POSSIBILITIES OF SUCH DAMAGES. APPLICABLE LAW MAY NOT
                          ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY OR
                          INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE
                          LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU. IN SUCH
                          CASES, Medmorize’S LIABILITY WILL BE LIMITED TO THE
                          FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
                          Medmorize’S LIABILITY TO YOU IS LIMITED TO THE
                          AMOUNTS, IF ANY, PAID BY YOU TO COSMAS HEALTH LLC
                          UNDER THIS AGREEMENT. THE FOREGOING LIMITATIONS WILL
                          APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE
                          LAW, REGARDLESS OF WHETHER Medmorize HAS BEEN ADVISED
                          OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF
                          WHETHER ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Attorney’s Fees.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          You and Cosmas Health LLC agree that failure to comply
                          with the Arbitration requirements as set forth in
                          Section 16 of this Agreement, renders a prevailing
                          party ineligible to receive attorney’s fees that they
                          would otherwise be entitled to receive. If any legal
                          action, including an action for declaratory relief, is
                          brought to enforce or interpret the provisions of this
                          Agreement, the prevailing Party will be entitled to
                          reasonable attorneys’ fees, which may be set by the
                          court in the same action or in a separate action
                          brought for that purpose, in addition to any other
                          relief to which that Party may be entitled.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Severability.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          If any provision of this Agreement or the application
                          of any provision of this Agreement to any person or
                          circumstance is to any extent held to be invalid or
                          unenforceable, the remainder of this Agreement or the
                          application of that provision to persons or
                          circumstances other than those as to which it is held
                          invalid or unenforceable, will not be affected, and
                          each provision of this Agreement will be valid and be
                          enforced to the fullest extent permitted by law.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Assignment.
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          &nbsp;Cosmas Health LLC may assign our rights and
                          obligations under these Terms. The Terms will inure to
                          the benefit of our successors, assigns, and licensees.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "0pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Entire Agreement.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          These Terms and any applicable Additional Terms, as
                          each may be amended as set forth herein, are the
                          entire agreement between you and &nbsp;Cosmas Health
                          LLC relating to the subject matter herein.
                        </span>
                      </p>
                    </li>
                    <li
                      dir="ltr"
                      style={{
                        listStyleType: "decimal",
                        fontSize: "10.5pt",
                        fontFamily: "Arial",
                        color: "#434343",
                        backgroundColor: "transparent",
                        fontWeight: 400,
                        fontStyle: "normal",
                        fontVariant: "normal",
                        textDecoration: "none",
                        verticalAlign: "baseline",
                        whiteSpace: "pre"
                      }}
                    >
                      <p
                        dir="ltr"
                        style={{
                          lineHeight: "1.7999999999999998",
                          marginTop: "0pt",
                          marginBottom: "8pt"
                        }}
                      >
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          <br />
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 700,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          Waiver.&nbsp;
                        </span>
                        <span
                          style={{
                            fontSize: "10.5pt",
                            fontFamily: "Arial",
                            color: "#434343",
                            backgroundColor: "transparent",
                            fontWeight: 400,
                            fontStyle: "normal",
                            fontVariant: "normal",
                            textDecoration: "none",
                            verticalAlign: "baseline",
                            whiteSpace: "pre-wrap"
                          }}
                        >
                          No waiver of any of these Terms by Medmorize is
                          binding unless authorized in writing by an executive
                          officer of &nbsp;Cosmas Health LLC. In the event that
                          &nbsp;Cosmas Health LLC waives a breach of any
                          provision of these Terms, such waiver will not be
                          construed as a continuing waiver of other breaches of
                          the same nature or other provisions of these Terms and
                          will in no manner affect the right of &nbsp;Cosmas
                          Health LLC to enforce the same at a later time.
                        </span>
                      </p>
                    </li>
                  </ol>
                  <p>
                    <br />
                  </p>
                  <p>
                    <br />
                  </p>
                </div>

                <Link to="/">
                  <button className="modal-link-btn">
                    <p className="m-1 mr-3 ml-3 text-white">Home</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
