import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logos/mm-txt.svg";

export default class NotFound extends Component {
  render() {
    return (
      <div className="container-fluid p-0 auth-background">
        <div className="row no-gutters">
          <div className="col-lg-3 m-auto p-2">
            <div className="text-center mt-5 mb-5"></div>
            <div className="auth-container mb-5">
              <div className="text-center pt-3 pb-3">
                <h1 className="display-1">
                  <i className="fas fa-exclamation-circle text-pyrlgray-light" />
                </h1>
                <h4 className="text-pyrlgray-light mb-4 mt-4">
                  Page Not Found
                </h4>
                <hr />
                <Link to="/">
                  <button className="modal-link-btn">
                    <p className="m-1 mr-3 ml-3 text-white">Home</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
