import React, { Component } from "react";
import StripeCheckout from "react-stripe-checkout";
import StripeLogo from "../../img/logos/stripeLogo.svg";

export default class Checkout1 extends Component {
  render() {
    return (
      <StripeCheckout
        amount={1200}
        token={token => {
          this.props.charge(token);
          this.props.spinner();
        }}
        // token={token => console.log(token)}
        allowRememberMe={false}
        image={StripeLogo}
        name={"Medmorize"}
        description={""}
        stripeKey={"pk_live_GoWSBnTwTuzpKGJOgqWpYVxZ003Ty3gESC"}
      >
        <button
          className="btn grad-btn opa-hover"
          style={{ width: "7px 50px", borderRadius: "27px" }}
        >
          Activate
        </button>
      </StripeCheckout>
    );
  }
}

// test: pk_test_vf98ptFf7YrZFG3spfdqAbNm003JfYD9E7
// prod: pk_live_GoWSBnTwTuzpKGJOgqWpYVxZ003Ty3gESC
