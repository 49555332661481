import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Initialize firebase
var firebaseConfig = {
  apiKey: "AIzaSyBu5v-YifjSdRWHy62_4oxA0Ho0B-T8IqY",
  authDomain: "medmorize-327b5.firebaseapp.com",
  databaseURL: "https://medmorize-327b5.firebaseio.com",
  projectId: "medmorize-327b5",
  storageBucket: "",
  messagingSenderId: "211763197438",
  appId: "1:211763197438:web:0718205991a1250348fcaf"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
