const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    ////// LOGIN ACTIONS
    case "LOGIN_ERROR":
      console.log("Login Failure!");
      return {
        ...state,
        authError: "Login failed"
      };
    case "LOGIN_SUCCESS":
      console.log("Login Success!");
      return {
        ...state,
        authError: null
      };

    ////// LOGOUT ACTIONS
    case "LOGOUT_SUCCESS":
      console.log("Logged out!");
      return state;

    ////// SIGNUP ACTIONS
    case "SIGNUP_SUCCESS":
      console.log("Sign Up success!");
      return {
        ...state,
        authError: null
      };
    case "SIGNUP_ERROR":
      console.log("Sign Up error");
      return {
        ...state,
        authError: action.err.message
      };
    ////// PASSWORD RESET ACTIONS
    case "PASSWORD_RESET":
      console.log("Password reset email sent!");
      alert(
        "Check your email for a link to reset your password. If you do not see a link within 5 minutes, try password reset again. Be sure to check your spam folder. Do not hesitate to reach out to hello@pyrls.com for any assistance."
      );
      window.location.reload();
      return state;
    ////// CHANGE EMAIL
    case "CHANGE_EMAIL":
      console.log("Email changed!");
      alert("Email changed successfully!");
      window.location.reload();
      return state;
    ////// CHANGE EMAIL ERROR
    case "CHANGE_EMAIL_ERROR":
      console.log("Change email error");
      return {
        ...state,
        authError: action.err.message
      };
    ////// CHANGE PASSWORD
    case "CHANGE_PASSWORD":
      console.log("Password changed!");
      alert("Password changed successfully!");
      window.location.reload();
      return state;
    ////// CHANGE PASSWORD ERROR
    case "CHANGE_PASSWORD_ERROR":
      console.log("Change password error");
      return {
        ...state,
        authError: action.err.message
      };
    ////// DELETE ACCOUNT
    case "DELETE_ACCOUNT":
      console.log("Password changed!");
      alert("Account deleted!");
      window.location.reload();
      return state;
    ////// DELETE ACCOUNT ERROR
    case "DELETE_ACCOUNT_ERROR":
      console.log("Delete account error");
      return {
        ...state,
        authError: action.err.message
      };
    default:
      return state;
  }
};

export default authReducer;
