import React, { Component } from "react";

export default class Design extends Component {
  render() {
    return (
      <div className="container">
        <div className="row no-gutters mb-5 p-3">
          <div className="col-md-4 m-auto">
            {/* /// */}
            <div
              id="demo"
              className="carousel slide"
              data-ride="carousel"
              data-interval="false"
              data-wrap="false"
            >
              <ul className="carousel-indicators">
                <li data-target="#demo" data-slide-to="0" className="active" />
                <li data-target="#demo" data-slide-to="1" />
                <li data-target="#demo" data-slide-to="2" />
              </ul>
              <div
                className="carousel-inner"
                style={{ backgroundColor: "offwhite" }}
              >
                <div className="carousel-item active">
                  <p>Hello1 Hello1 Hello1</p>
                  <p>Hello1</p>
                  <p>Hello1</p>
                  <p>Hello1</p>
                  <p>Hello1</p>
                </div>
                <div className="carousel-item">
                  <p>Hello2 Hello2 Hello2</p>
                  <p>Hello2</p>
                  <p>Hello2</p>
                  <p>Hello2</p>
                  <p>Hello2</p>
                </div>
                <div className="carousel-item">
                  <p>Hello3 Hello3 Hello3</p>
                  <p>Hello3</p>
                  <p>Hello3</p>
                  <p>Hello3</p>
                  <p>Hello3</p>
                </div>
              </div>
              <a
                className="carousel-control-prev"
                href="#demo"
                data-slide="prev"
              >
                {/* <span className="carousel-control-prev-icon" /> */}
                <i className="fas fa-chevron-left car-control" />
              </a>
              <a
                className="carousel-control-next"
                href="#demo"
                data-slide="next"
              >
                {/* <span className="carousel-control-next-icon" /> */}
                <i className="fas fa-chevron-right car-control" />
              </a>
            </div>
            {/* /// */}
          </div>
        </div>
        <div className="row no-gutters p-3">
          <div className="col-md-12">
            <h1>Heading 1</h1>
            <h2>Heading 2</h2>
            <h3>Heading 3</h3>
            <h4>Heading 4</h4>
            <h5>Heading 5</h5>
            <h6>Heading 6</h6>
            <p className="mb-1">Paragraph</p>
            <p className="text-sm mb-1">Text-sm</p>
            <p className="text-x-sm">Text-x-sm</p>
            <hr />
            <p className="boldest">Boldest text</p>
            <p className="bold">Bold text</p>
            <p>Regular text</p>
            <p className="light">Light text</p>
            <hr />
            <p className="text-pyrlple">Pyrlple text</p>
            <p className="text-dark">Black text (.text-dark)</p>
            <p className="text-pyrlgray">Pyrlgray text (default color)</p>
            <p className="text-pyrlgray-light">Pyrlgray-light text</p>
          </div>
        </div>
        <div className="row no-gutters mb-5 dark-mode p-3">
          <div className="col-md-12">
            <h1 className="text-pyrlgray dark-mode">Heading 1</h1>
            <h2 className="text-pyrlgray dark-mode">Heading 2</h2>
            <h3 className="text-pyrlgray dark-mode">Heading 3</h3>
            <h4 className="text-pyrlgray dark-mode">Heading 4</h4>
            <h5 className="text-pyrlgray dark-mode">Heading 5</h5>
            <h6 className="text-pyrlgray dark-mode">Heading 6</h6>
            <p className="mb-1 text-pyrlgray dark-mode">Paragraph</p>
            <p className="text-sm mb-1 text-pyrlgray dark-mode">Text-sm</p>
            <p className="text-x-sm text-pyrlgray dark-mode">Text-x-sm</p>
            <hr />
            <p className="boldest text-pyrlgray dark-mode">Boldest text</p>
            <p className="bold text-pyrlgray dark-mode">Bold text</p>
            <p className="text-pyrlgray dark-mode">Regular text</p>
            <p className="light text-pyrlgray dark-mode">Light text</p>
            <hr />
            <p className="text-pyrlple dark-mode">Pyrlple text</p>
            <p className="text-dark dark-mode">Black text (.text-dark)</p>
            <p className="text-pyrlgray dark-mode">
              Pyrlgray text (default color)
            </p>
            <p className="text-pyrlgray-light dark-mode">Pyrlgray-light text</p>
          </div>
        </div>
      </div>
    );
  }
}
