import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// Actions
import {
  changeEmail,
  changePassword,
  logOut,
  deleteAccount
} from "../../store/actions/authActions";
import {
  changeName,
  changeOccupation,
  updateDemographics
} from "../../store/actions/profileActions";
// Components
import Spinner from "../common/Spinner";
import Topnav from "../navs/Topnav";

class Account extends Component {
  state = {
    newName: "",
    newEmail: "",
    newOccupation: "",
    currentPassword: "",
    oldPassword: "",
    newPassword: "",
    verifyNewPassword: "",
    // demographic info
    company: "",
    jobTitle: "",
    degree: "",
    graduationYear: "",
    school: "",
    interests: "",
    location: "",
    status: ""
  };

  formType = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      // console.log(this.state);
    });
  };
  onSubmitNewEmail = e => {
    e.preventDefault();

    let credentials = {
      oldEmail: this.props.auth.email,
      newEmail: this.state.newEmail,
      currentPassword: this.state.currentPassword
    };

    this.props.changeEmail(credentials);
  };
  onSubmitNewName = e => {
    e.preventDefault();
    this.props.changeName(this.state.newName);
    this.setState(
      { newName: "" },
      () => (document.getElementById("newName-input").value = "")
    );
  };
  onSubmitNewOccupation = e => {
    e.preventDefault();
    this.props.changeOccupation(this.state.newOccupation);
    this.setState(
      { newOccupation: "" },
      () => (document.getElementById("newOccupation-input").value = "")
    );
  };
  onSubmitNewDemographics = e => {
    e.preventDefault();

    let company;
    this.state.company.length > 0
      ? (company = this.state.company)
      : (company = this.props.profile.userInfo.company);
    let jobTitle;
    this.state.jobTitle.length > 0
      ? (jobTitle = this.state.jobTitle)
      : (jobTitle = this.props.profile.userInfo.jobTitle);
    let degree;
    this.state.degree.length > 0
      ? (degree = this.state.degree)
      : (degree = this.props.profile.userInfo.degree);
    let graduationYear;
    this.state.graduationYear.length > 0
      ? (graduationYear = this.state.graduationYear)
      : (graduationYear = this.props.profile.userInfo.graduationYear);
    let school;
    this.state.school.length > 0
      ? (school = this.state.school)
      : (school = this.props.profile.userInfo.school);
    let interests;
    this.state.interests.length > 0
      ? (interests = this.state.interests)
      : (interests = this.props.profile.userInfo.interests);
    let location;
    this.state.location.length > 0
      ? (location = this.state.location)
      : (location = this.props.profile.userInfo.location);
    let status;
    this.state.status.length > 0
      ? (status = this.state.status)
      : (status = this.props.profile.userInfo.status);
    let occupation;
    this.state.newOccupation.length > 0
      ? (occupation = this.state.newOccupation)
      : (occupation = this.props.profile.userInfo.occupation);

    console.log(company.length);

    let newDemographics = {
      company,
      jobTitle,
      degree,
      graduationYear,
      school,
      interests,
      location,
      status,
      occupation
    };
    this.props.updateDemographics(newDemographics);
  };
  onSubmitNewPassword = e => {
    e.preventDefault();
    if (this.state.newPassword === this.state.verifyNewPassword) {
      let credentials = {
        email: this.props.auth.email,
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPassword
      };
      this.props.changePassword(credentials);
    } else {
      document.getElementById("match-passwords").style.visibility = "visible";
    }
  };
  onSubmitDeleteAccount = e => {
    e.preventDefault();
    let credentials = {
      email: this.props.auth.email,
      currentPassword: this.state.currentPassword
    };
    let areYouSure = window.confirm(
      "Are you sure you want to delete your account?"
    );
    if (areYouSure == true) {
      this.props.deleteAccount(credentials);
    }
    this.setState(
      { currentPassword: "" },
      () => (document.getElementById("deleteAccount-password-input").value = "")
    );
  };
  deleteAccountControl() {
    document.getElementById("delete-account-btn").style.display = "none";
    document.getElementById("delete-account-box").style.display = "block";
  }

  render() {
    const { profile } = this.props;

    let displayContent;

    if (profile.name === undefined) {
      displayContent = <Spinner />;
    } else {
      const {
        company,
        degree,
        graduationYear,
        interests,
        jobTitle,
        location,
        school,
        status
      } = this.props.profile.userInfo;

      displayContent = (
        <div className="container-fluid p-0">
          <div className="main-container">
            <Topnav />
            <div
              className="main-content-container"
              id="main-content-outer-container"
            >
              <div className="container p-0">
                <div className="row no-gutters">
                  <div className="col-lg-9 p-3">
                    <div className="account-container">
                      <div className="row no-gutters">
                        <div className="col-md-9">
                          <div className="simple-flexbox">
                            <div className="avatar m-3">
                              <h1 className="avatar-letter text-white mb-0">
                                {profile.name.substring(0, 1)}
                              </h1>
                            </div>
                            <div className="m-3">
                              <h5 className="text-pyrlgray boldest mb-0">
                                {profile.name}
                              </h5>
                              <h6 className="text-bluegray bold mt-2">
                                {profile.email}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="simple-flexbox-2 p-3">
                            <div
                              className="content-container-4 account-btn"
                              data-toggle="collapse"
                              data-target="#change-info"
                            >
                              <p className="mb-0">
                                <i className="fas fa-edit mr-2 text-bluegray" />
                                Edit Information
                              </p>
                            </div>
                            <div
                              className="content-container-4 account-btn"
                              data-toggle="collapse"
                              data-target="#change-password"
                            >
                              <p className="mb-0">
                                <i className="fas fa-lock mr-2 text-bluegray" />
                                Change Password
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row no-gutters collapse" id="change-info">
                        <div className="col-md-12">
                          <hr />
                        </div>
                        <div className="col-md-6 p-3 text-center">
                          <h6 className="boldest text-sm text-bluegray some-space mb-0">
                            EDIT NAME
                          </h6>
                          <div className="p-2 mt-3">
                            <form onSubmit={this.onSubmitNewName}>
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="newName-input"
                                  className="form-control input-3 m-auto"
                                  name="newName"
                                  placeholder="Name"
                                  onChange={this.formType}
                                  value={
                                    this.state.newName.length > 0
                                      ? this.state.newName
                                      : this.props.profile.name.length > 0
                                      ? this.props.profile.name
                                      : ""
                                  }
                                />
                              </div>
                              <button
                                type="submit"
                                className="content-container-5 submit-btn-1 mt-3"
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>

                        <div className="col-md-6 m-auto p-3 text-center">
                          <h6 className="boldest text-sm text-bluegray some-space mb-0">
                            EDIT EMAIL
                          </h6>
                          <div className="p-2 mt-3">
                            <form onSubmit={this.onSubmitNewEmail}>
                              <div className="form-group">
                                <input
                                  type="text"
                                  id="newEmail-input"
                                  className="form-control input-3 m-auto"
                                  name="newEmail"
                                  placeholder="New Email Address"
                                  onChange={this.formType}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control input-3 m-auto"
                                  name="currentPassword"
                                  placeholder="Enter Password"
                                  onChange={this.formType}
                                />
                              </div>
                              {this.props.authError && (
                                <p className="text-danger mb-2">
                                  {this.props.authError}
                                </p>
                              )}
                              <button
                                type="submit"
                                className="content-container-5 submit-btn-1 mt-3"
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row no-gutters collapse"
                        id="change-password"
                      >
                        <div className="col-md-12">
                          <hr />
                        </div>
                        <div className="col-md-6 m-auto p-3 text-center">
                          <h6 className="boldest text-sm text-bluegray some-space mb-0">
                            CHANGE PASSWORD
                          </h6>
                          <div className="p-2 mt-3">
                            <form onSubmit={this.onSubmitNewPassword}>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control input-3 m-auto"
                                  name="oldPassword"
                                  placeholder="Old Password"
                                  onChange={this.formType}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control input-3 m-auto"
                                  name="newPassword"
                                  placeholder="New Password"
                                  onChange={this.formType}
                                />
                              </div>
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control input-3 m-auto"
                                  name="verifyNewPassword"
                                  placeholder="Confirm Password"
                                  onChange={this.formType}
                                />
                              </div>
                              <p
                                id="match-passwords"
                                className="text-danger mb-2"
                              >
                                Passwords must match
                              </p>
                              {this.props.authError && (
                                <p className="text-danger mb-3">
                                  {this.props.authError}
                                </p>
                              )}
                              <button
                                type="submit"
                                className="content-container-5 submit-btn-1"
                              >
                                Submit
                              </button>
                              <Link to="/password-reset">
                                <p className="text-bluegray mb-0">
                                  Forgot Password
                                </p>
                              </Link>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row no-gutters">
                  <div className="col-lg-9">
                    <hr className="ml-3 mr-3 mt-4 mb-2" />
                  </div>
                </div> */}
                {/* <div className="row no-gutters mb-1">
                  <div className="col-lg-5 p-4">
                    <h6 className="boldest text-pyrlgray mb-1">
                      Demographic Information
                    </h6>
                    <p className="text-pyrlgray text-sm">
                      This information is used{" "}
                      <span className="boldest">strictly</span> to better
                      understand Pyrls' audience and personalize your
                      experience. Your responses are greatly appreciated as we
                      continuously work to improve Pyrls.
                    </p>
                    <br />
                    <form onSubmit={this.onSubmitNewDemographics}>
                      <p className="text-bluegray boldest mb-3">Professional</p>
                      <div className="form-group">
                        <select
                          className="form-control select-1"
                          name="status"
                          onChange={this.formType}
                        >
                          <option value>
                            {status.length > 0 ? status : "Select status"}
                          </option>
                          <option>Professional</option>
                          <option>Resident</option>
                          <option>Student</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-2 demographics-input"
                          name="jobTitle"
                          placeholder="Job Title"
                          value={
                            this.state.jobTitle.length > 0
                              ? this.state.jobTitle
                              : jobTitle.length > 0
                              ? jobTitle
                              : ""
                          }
                          onChange={this.formType}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-2 demographics-input"
                          name="company"
                          placeholder="Company"
                          onChange={this.formType}
                          value={
                            this.state.company.length > 0
                              ? this.state.company
                              : company.length > 0
                              ? company
                              : ""
                          }
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-2 demographics-input"
                          name="location"
                          placeholder="Location"
                          value={
                            this.state.location.length > 0
                              ? this.state.location
                              : location.length > 0
                              ? location
                              : ""
                          }
                          onChange={this.formType}
                        />
                      </div>
                      <br />
                      <p className="text-bluegray boldest mb-3">Education</p>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-2 demographics-input"
                          name="school"
                          placeholder="School/University"
                          value={
                            this.state.school.length > 0
                              ? this.state.school
                              : school.length > 0
                              ? school
                              : ""
                          }
                          onChange={this.formType}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-2 demographics-input"
                          name="degree"
                          placeholder="Degree(s) completed/in-progress"
                          value={
                            this.state.degree.length > 0
                              ? this.state.degree
                              : degree.length > 0
                              ? degree
                              : ""
                          }
                          onChange={this.formType}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control input-2 demographics-input"
                          name="graduationYear"
                          placeholder="Grad year of most terminal degree"
                          value={
                            this.state.graduationYear.length > 0
                              ? this.state.graduationYear
                              : graduationYear.length > 0
                              ? graduationYear
                              : ""
                          }
                          onChange={this.formType}
                        />
                      </div>
                      <br />
                      <p className="text-bluegray boldest mb-3">
                        Areas of Interest
                      </p>
                      <div className="form-group">
                        <textarea
                          rows="5"
                          type="text"
                          className="form-control input-2 demographics-input"
                          name="interests"
                          placeholder="E.g. cardiovascular, diabetes, geriatrics, infectious diseases, family medicine, ambulatory care pharmacy, oncology nursing, etc..."
                          value={
                            this.state.interests.length > 0
                              ? this.state.interests
                              : interests.length > 0
                              ? interests
                              : ""
                          }
                          onChange={this.formType}
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="content-container-5 submit-btn-1 mt-3"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                 */}
                <div className="row no-gutters mb-5">
                  <div className="col-lg-9">
                    <hr className="ml-3 mr-3 mt-5" />
                  </div>
                  <div className="col-lg-9 text-center mt-5">
                    <button
                      type="submit"
                      className="content-container-5 submit-btn-1"
                      onClick={this.props.logOut}
                    >
                      Logout
                    </button>
                  </div>
                  <div className="col-lg-9">
                    <hr className="ml-3 mr-3 mt-5" />
                  </div>
                  <div className="col-lg-9">
                    <div className="row no-gutters">
                      <div className="col-md-12 pt-3 pl-3 pr-3 text-center m-auto">
                        <h5 className="boldest text-danger mb-4">
                          Danger Zone
                        </h5>
                        <button
                          className="content-container-5 red-btn-1"
                          id="delete-account-btn"
                          onClick={this.deleteAccountControl}
                        >
                          Delete Account
                        </button>
                      </div>
                    </div>
                    <div className="row no-gutters" id="delete-account-box">
                      <div className="col-md-7 m-auto p-3 text-center">
                        <form onSubmit={this.onSubmitDeleteAccount}>
                          <div className="form-group">
                            <input
                              id="deleteAccount-password-input"
                              type="password"
                              className="form-control input-3 m-auto"
                              name="currentPassword"
                              placeholder="Enter Password"
                              onChange={this.formType}
                              style={{ maxWidth: "227px" }}
                            />
                          </div>
                          {this.props.authError && (
                            <p className="text-danger mb-3">
                              {this.props.authError}
                            </p>
                          )}
                          <button
                            type="submit"
                            className="content-container-5 red-btn-1"
                          >
                            Confirm
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return displayContent;
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeEmail: newEmail => dispatch(changeEmail(newEmail)),
    changeOccupation: newOccupation =>
      dispatch(changeOccupation(newOccupation)),
    updateDemographics: newDemographics =>
      dispatch(updateDemographics(newDemographics)),
    // credentials includes email, old password and new password
    changePassword: credentials => dispatch(changePassword(credentials)),
    logOut: () => dispatch(logOut()),
    changeName: newName => dispatch(changeName(newName)),
    deleteAccount: credentials => dispatch(deleteAccount(credentials))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Account);
