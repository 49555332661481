import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logos/mm-txt.svg";

export default class Disclaimer extends Component {
  render() {
    return (
      <div className="container-fluid p-0 auth-background">
        <div className="row no-gutters">
          <div className="col-lg-6 m-auto p-2">
            <div className="text-center mt-5 mb-5"></div>
            <div className="auth-container mb-5">
              <div className="text-center pt-3 pb-3">
                <Link to="/">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ width: "200px" }}
                    className="opa-hover"
                  />
                </Link>
                <h4 className="text-pyrlgray-light mb-4 mt-4 boldest">
                  Disclaimer
                </h4>
                <hr />
                <p className="text-left">
                  The information provided by this website (Medmorize.co) is for
                  general informational purposes only. All information in the
                  website is provided in good faith, however the developer makes
                  no representation or warranty of any kind, express or implied,
                  regarding the accuracy, adequacy, validity, reliability,
                  availability or completeness of any information on the
                  website. UNDER NO CIRCUMSTANCE SHALL THE DEVELOPER HAVE ANY
                  LIABILITY TO YOU FOR ANY LOSS, DAMAGE OR HARM OF ANY KIND
                  INCURRED AS A RESULT OF THE USE OF THE WEBSITE OR RELIANCE ON
                  ANY INFORMATION PROVIDED ON THE WEBSITE. YOUR USE OF THE
                  WEBSITE AND YOUR RELIANCE ON ANY INFORMATION PROVIDED ON THE
                  WEBSITE IS SOLELY AT YOUR OWN RISK.
                  <br />
                  <br />
                  The website may contain links to other websites or content
                  belonging to or originating from third parties or links to
                  websites and features in banners or other advertising. Such
                  external links are not investigated, monitored, or checked for
                  accuracy, adequacy, validity, reliability, availability or
                  completeness by the developer. WE DO NOT WARRANT, ENDORSE,
                  GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR
                  RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES
                  LINKED THROUGH THE SITE OR ANY WEBSITE FEATURE LINKED IN ANY
                  BANNER OR OTHER ADVERTISING. THE DEVELOPER WILL NOT BE A PARTY
                  TO OR IN ANY WAY RESPONSIBLE FOR MONITORING ANY TRANSACTION
                  BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                  This website cannot and does not contain medical/health
                  advice.
                  <br />
                  <br />
                  The medical/health information is provided for general
                  informational and educational purposes only and is not a
                  substitute for professional advice. Accordingly, before taking
                  any actions based upon such information, the developer
                  encourages you to consult with the appropriate professionals.
                  The developer does not provide any kind of medical/health
                  advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED ON
                  THIS WEBSITE IS SOLELY AT YOUR OWN RISK.
                </p>
                <Link to="/">
                  <button className="modal-link-btn">
                    <p className="m-1 mr-3 ml-3 text-white">Home</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
