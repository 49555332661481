import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// Actions
import { logOut } from "../../store/actions/authActions";
// Components
import Spinner from "../common/Spinner";
import Topnav from "../navs/Topnav";
// data
import * as dataJson from "../../data/data.json";

// import * as drugJson from "../../data/drugData.json";

/*
let drugsData = drugJson.default;
      let drug = drugsData.find(drug => {
        return `${drug.genericName.toLowerCase()}` === `${drugUrl}`;
      });
*/

class TherapeuticClass extends Component {
  state = {
    qType: "",
    qNum: "",
    qTopic: "",
    display: "",

    onQuestion: 1,
    numOfQuestions: "",
    randomizedYet: false,

    numCorrect: 0,
    numIncorrect: 0,

    quizTypeOpen: true,
    topicOpen: false,
    numQuestionsOpen: false
  };

  selectType(e) {
    this.setState({ qType: e.target.id });
  }
  selectNum(e) {
    this.setState({ qNum: e.target.id });
  }
  selectTopic(e) {
    this.setState({ qTopic: e.target.id });
  }
  start() {
    this.setState({ display: "loading" }, () => {
      let setNum;
      this.state.qNum === "ten" && (setNum = 10);
      this.state.qNum === "twenty" && (setNum = 20);
      this.state.qNum === "thirty" && (setNum = 30);

      setTimeout(() => {
        this.setState({ display: "quiz", numOfQuestions: setNum });
      }, 570);
    });
  }
  exit() {
    // this.setState({ display: "" });
    window.location.reload();
  }
  shuffle(array) {
    var currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  componentDidUpdate() {
    if (this.state.qTopic === "coldandallergies") {
      if (document.getElementById("twenty")) {
        document.getElementById("twenty").style.display = "none";
        if (this.props.qNum === "twenty") {
          this.setState({ qNum: "" });
        }
      }
    }
    if (this.state.qTopic !== "coldandallergies") {
      if (document.getElementById("twenty")) {
        document.getElementById("twenty").style.display = "block";
      }
    }
  }

  render() {
    const { profile } = this.props;
    let data = dataJson.default;

    let displayContent;

    if (profile.name === undefined) {
      displayContent = <Spinner />;
    } else {
      /*
      if (profile.subscription.active === false) {
        // displayContent = <Subscribe />;
       } else {
        displayContent = (
          <div
            className="container p-0"
            style={{
              maxWidth: "1000px",
              minHeight: "100vh"
            }}
          >
            <div className="row no-gutters">
              <p>hello</p>
              <button onClick={this.props.logOut}>logout</button>
            </div>
          </div>
        );
       }
       */

      let quizTypeControl;
      let topicControl;
      let numQuestionsControl;

      this.state.quizTypeOpen
        ? (quizTypeControl = "show")
        : (quizTypeControl = "");
      this.state.topicOpen ? (topicControl = "show") : (topicControl = "");
      this.state.numQuestionsOpen
        ? (numQuestionsControl = "show")
        : (numQuestionsControl = "");

      let menu = (
        <div
          id="quiz-menu"
          className="container p-0"
          style={{
            maxWidth: "1000px",
            minHeight: "100vh"
          }}
        >
          <div className="row no-gutters mb-0">
            <div className="col-md-12">
              <Topnav />
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-md-12 text-center">
              <h3 className="light">Therapeutic Class</h3>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-md-8 m-auto p-2">
              <hr className="light-hr mb-4 mt-4" />
              <div className="row no-gutters">
                <div className="col-sm-6 m-auto text-center quiz-menu">
                  <button
                    // data-toggle="collapse"
                    // data-target="#quiz-type"
                    className="quiz-menu-button"
                    onClick={() => {
                      if (this.state.quizTypeOpen) {
                        this.setState({ quizTypeOpen: false });
                      } else {
                        this.setState({ quizTypeOpen: true });
                      }
                    }}
                  >
                    <h5 className="bold text-left mb-0">
                      <span className="mr-3">Select Quiz Type</span>
                      <i
                        className="fas fa-chevron-down quiz-menu-arrow mb-0 ml-5"
                        style={
                          this.state.quizTypeOpen
                            ? { transform: "rotate(-90deg)" }
                            : {}
                        }
                      ></i>
                    </h5>
                  </button>
                  {/* #c35fff */}
                  <div
                    id="quiz-type"
                    className={`collapse menu-box ${quizTypeControl}`}
                  >
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qType === "dc"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectType(e);
                        if (!this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: true,
                              quizTypeOpen: false
                            });
                          }, 270);
                        }
                      }}
                      id="dc"
                    >
                      Drug → Class
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qType === "cd"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectType(e);
                        if (!this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: true,
                              quizTypeOpen: false
                            });
                          }, 270);
                        }
                      }}
                      id="cd"
                    >
                      Class → Drug
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              <div className="row no-gutters pt-3">
                <br />
                <div className="col-sm-6 m-auto text-center quiz-menu">
                  <button
                    // data-toggle="collapse"
                    // data-target="#quiz-topic"
                    className="quiz-menu-button"
                    onClick={() => {
                      if (this.state.topicOpen) {
                        this.setState({ topicOpen: false });
                      } else {
                        this.setState({ topicOpen: true });
                      }
                    }}
                  >
                    <h5 className="bold text-center mb-0">
                      <span className="mr-3">Select Quiz Topic</span>
                      <i
                        className="fas fa-chevron-down ml-5 quiz-menu-arrow mb-0"
                        style={
                          this.state.topicOpen
                            ? { transform: "rotate(-90deg)" }
                            : {}
                        }
                      ></i>
                    </h5>
                  </button>

                  <div
                    id="quiz-topic"
                    className={`collapse menu-box ${topicControl}`}
                  >
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "all"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="all"
                    >
                      All Topics
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "cardiovascular"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="cardiovascular"
                    >
                      Cardiovascular
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "coldandallergies"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="coldandallergies"
                    >
                      Cold & Allergies
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "diabetes"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="diabetes"
                    >
                      Diabetes
                    </div>

                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "femaleAndMaleHealth"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="femaleAndMaleHealth"
                    >
                      Female & Male Health
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "gastrointestinal"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="gastrointestinal"
                    >
                      Gastrointestinal
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "infectiousDiseases"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="infectiousDiseases"
                    >
                      Infectious Diseases
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "neurologic"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="neurologic"
                    >
                      Neurologic
                    </div>

                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "pain"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="pain"
                    >
                      Pain
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "pulmonary"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="pulmonary"
                    >
                      Pulmonary
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qTopic === "psychiatric"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectTopic(e);
                        if (this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: false,
                              numQuestionsOpen: true
                            });
                          }, 270);
                        }
                      }}
                      id="psychiatric"
                    >
                      Psychiatric
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              <div className="row no-gutters pt-3">
                <br />
                <div className="col-sm-6 m-auto text-center quiz-menu">
                  <button
                    // data-toggle="collapse"
                    // data-target="#quiz-num"
                    className="quiz-menu-button"
                    onClick={() => {
                      if (this.state.numQuestionsOpen) {
                        this.setState({ numQuestionsOpen: false });
                      } else {
                        this.setState({ numQuestionsOpen: true });
                      }
                    }}
                  >
                    <h5 className="bold text-center mb-0">
                      Number of Questions
                      <i
                        className="fas fa-chevron-down ml-4 quiz-menu-arrow mb-0"
                        style={
                          this.state.numQuestionsOpen
                            ? { transform: "rotate(-90deg)" }
                            : {}
                        }
                      ></i>
                    </h5>
                  </button>

                  <div
                    id="quiz-num"
                    className={`collapse menu-box ${numQuestionsControl}`}
                  >
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qNum === "ten"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectNum(e);
                        if (!this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              numQuestionsOpen: false
                            });
                          }, 270);
                        }
                      }}
                      id="ten"
                    >
                      10
                    </div>
                    <br />
                    <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qNum === "twenty"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectNum(e);
                        if (!this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              numQuestionsOpen: false
                            });
                          }, 270);
                        }
                      }}
                      id="twenty"
                    >
                      20
                    </div>
                    <br />
                    {/* <div
                      className="option-card m-auto p-2"
                      style={
                        this.state.qNum === "thirty"
                          ? { backgroundColor: "#c35fff" }
                          : { backgroundColor: "#5397fd" }
                      }
                      onClick={e => {
                        this.selectNum(e);
                        if (!this.state.topicOpen) {
                          setTimeout(() => {
                            this.setState({
                              topicOpen: true,
                              numQuestionsOpen: false
                            });
                          }, 270);
                        }
                      }}
                      id="thirty"
                    >
                      30
                    </div>
                    <br /> */}
                  </div>
                </div>
              </div>

              <hr className="light-hr mb-4 mt-4" />
              <div
                className="option-card m-auto p-2"
                style={
                  this.state.qType.length > 0 &&
                  this.state.qNum.length > 0 &&
                  this.state.qTopic.length > 0
                    ? { backgroundColor: "#4AD99F", opacity: "1" }
                    : {
                        backgroundColor: "#5397fd",
                        opacity: "0.57"
                      }
                }
                onClick={this.start.bind(this)}
                onClick={
                  this.state.qType.length > 0 && this.state.qNum.length > 0
                    ? this.start.bind(this)
                    : () => {}
                }
              >
                Start
              </div>
              <br />
            </div>
          </div>
        </div>
      );

      let done = (
        <div
          className="container p-0"
          style={{
            maxWidth: "1000px",
            minHeight: "100vh"
          }}
        >
          <div className="row no-gutters mb-1 mt-4">
            <div className="col-md-6 m-auto text-center">
              <h2 className="boldest mb-4">Complete!</h2>
              <h1
                className="boldest display-1 mb-0"
                style={{ color: "#4AD99F" }}
              >
                {this.state.numCorrect}
              </h1>
              <h5 style={{ color: "#4AD99F" }} className="mb-3 boldest">
                Correct
              </h5>
              <h1
                className="boldest display-1 mb-0"
                style={{ color: "#d94a69" }}
              >
                {this.state.numIncorrect}
              </h1>
              <h5 style={{ color: "#d94a69" }} className="mb-3 boldest">
                Incorrect
              </h5>
              <hr className="light-hr mt-5 mb-5" />
              <div
                className="option-card m-auto p-2"
                style={{ backgroundColor: "#5397fd" }}
                onClick={() => {
                  this.setState({
                    numCorrect: 0,
                    numIncorrect: 0,
                    onQuestion: 1,
                    display: "quiz"
                  });
                }}
                id="exit-now"
              >
                Retake
              </div>
              <br />
              <div
                className="option-card m-auto p-2"
                style={{ backgroundColor: "#c35fff" }}
                onClick={this.exit.bind(this)}
                id="exit-now"
              >
                Exit
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      );

      /////////////////////////////////////
      // QUESTION SET LOGIC
      /////////////////////////////////////

      let questionTitle;
      let questionSet = [];

      // Shuffle the whole data set
      if (!this.state.randomizedYet) {
        this.shuffle(data);
        this.setState({ randomizedYet: true });
      }

      data = data.filter(item => item.quizExclusion.includes("tc") === false);

      let cardiovascular = data.filter(item =>
        item.drugCategories.includes("Cardiovascular")
      );
      let coldAndAllergies = data.filter(item =>
        item.drugCategories.includes("Cold and Allergies")
      );
      let diabetes = data.filter(item =>
        item.drugCategories.includes("Diabetes")
      );
      let femaleAndMaleHealth = data.filter(item =>
        item.drugCategories.includes("Female and Male Health")
      );
      let gastrointestinal = data.filter(item =>
        item.drugCategories.includes("Gastrointestinal")
      );
      let infectiousDiseases = data.filter(item =>
        item.drugCategories.includes("Infectious Diseases")
      );
      let neurologic = data.filter(item =>
        item.drugCategories.includes("Neurologic")
      );
      let pain = data.filter(item => item.drugCategories.includes("Pain"));
      let pulmonary = data.filter(item =>
        item.drugCategories.includes("Pulmonary")
      );
      let psychiatric = data.filter(item =>
        item.drugCategories.includes("Psychiatric")
      );
      // Drug -> Class
      if (this.state.qType === "dc") {
        questionTitle = "Select the drug class for";
        if (this.state.qTopic === "all") {
          data.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "cardiovascular") {
          cardiovascular.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "coldAndAllergies") {
          coldAndAllergies.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "diabetes") {
          diabetes.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "femaleAndMaleHealth") {
          femaleAndMaleHealth.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "gastrointestinal") {
          gastrointestinal.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "infectiousDiseases") {
          infectiousDiseases.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "neurologic") {
          neurologic.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "pain") {
          pain.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "pulmonary") {
          pulmonary.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "psychiatric") {
          psychiatric.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );

            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );
            // Just return the classess
            let classes = drugsWithClassExceptQuestion.map(
              item => item.drugClass
            );
            // Get an unique list of the classes
            let unique = [...new Set(classes)];

            let optionsArray = [
              { text: q.drugClass, id: "correct" },
              {
                text: unique[0],
                id: "option1"
              },
              {
                text: unique[1],
                id: "option2"
              },
              {
                text: unique[2],
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.genericName,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
      }
      // Class -> Drug
      if (this.state.qType === "cd") {
        questionTitle = "Select the drug in this class";
        if (this.state.qTopic === "all") {
          data.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "cardiovascular") {
          cardiovascular.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];

            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "coldAndAllergies") {
          coldAndAllergies.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "diabetes") {
          diabetes.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "femaleAndMaleHealth") {
          femaleAndMaleHealth.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "gastrointestinal") {
          gastrointestinal.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "infectiousDiseases") {
          infectiousDiseases.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "neurologic") {
          neurologic.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "pain") {
          pain.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "pulmonary") {
          pulmonary.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
        if (this.state.qTopic === "psychiatric") {
          psychiatric.forEach(q => {
            // create array of brand names to pick questions from
            let brnds = q.brandNames;
            // Shuffle the brand names in case there's multiple names (e.g. Crestor, Ezallor)
            this.shuffle(brnds);
            // select primary drug category
            let qCategory = q.drugCategories[0];
            // get all drugs from the question's category
            let drugsFromCategory = data.filter(item =>
              item.drugCategories.includes(qCategory)
            );
            // remove the question drug
            let drugsFromCategoryExceptQuestion = drugsFromCategory.filter(
              item => item.genericName !== q.genericName
            );
            // get all drugs without same class as question drug
            let drugsWithClassExceptQuestion = drugsFromCategoryExceptQuestion.filter(
              item => {
                return item.drugClass !== q.drugClass;
              }
            );

            let optionsArray = [
              { text: q.genericName, id: "correct" },
              {
                text: drugsWithClassExceptQuestion[0].genericName,
                id: "option1"
              },
              {
                text: drugsWithClassExceptQuestion[1].genericName,
                id: "option2"
              },
              {
                text: drugsWithClassExceptQuestion[2].genericName,
                id: "option3"
              }
            ];
            // shuffle options
            this.shuffle(optionsArray);
            // question data package
            // {
            //   question: "Lipitor",
            //   options: [ { text: "Atorvastatin", id: "correct" }, { text: "Pravastatin", id: "option1" }, ... ]
            // }
            let newQ = {
              question: q.drugClass,
              options: optionsArray
            };
            questionSet.push(newQ);
          });
        }
      }
      /////////////////////////////////////
      // END QUESTION SET LOGIC
      /////////////////////////////////////

      let quiz = (
        <div
          className="container p-0"
          style={{
            maxWidth: "1000px",
            minHeight: "100vh"
          }}
        >
          <div className="row no-gutters mb-1">
            <div className="col-md-6 m-auto text-right">
              <h4>
                <i
                  className="fas fa-times mt-3 mr-3 text-gray-light opa-hover"
                  style={{
                    cursor: "pointer",
                    position: "fixed",
                    right: "0px",
                    top: "0px",
                    zIndex: "7777"
                  }}
                  onClick={this.exit.bind(this)}
                ></i>
              </h4>
            </div>
          </div>
          <div className="row no-gutters mb-1 mt-5">
            <div className="col-md-6 m-auto text-center">
              <h5 className="light" id="question-title">
                {questionTitle}
              </h5>
              <h4 className="boldest">
                {this.state.display === "quiz"
                  ? `${questionSet[this.state.onQuestion - 1].question}`
                  : ""}
              </h4>
              <br />
              {this.state.display === "quiz"
                ? questionSet[this.state.onQuestion - 1].options.map(opt => {
                    let correctOption = (
                      <React.Fragment key={opt.id}>
                        <div
                          className="option-card quiz-option m-auto p-2"
                          style={{
                            borderRadius: "12px"
                          }}
                          onClick={() => {
                            // Make page unclickable
                            document.getElementById(
                              "the-main-container-id"
                            ).style.pointerEvents = "none";
                            let numberCorrectSoFar = this.state.numCorrect;
                            let currentOnQuestion = this.state.onQuestion;
                            let correctOption = document.getElementById(
                              "correct"
                            );
                            let wrong1Option = document.getElementById(
                              "option1"
                            );
                            let wrong2Option = document.getElementById(
                              "option2"
                            );
                            let wrong3Option = document.getElementById(
                              "option3"
                            );
                            correctOption.style.backgroundColor = "#4cf5b0";
                            correctOption.style.transform = "scale(1.17)";
                            wrong1Option.style.backgroundColor = "#d94a69";
                            wrong2Option.style.backgroundColor = "#d94a69";
                            wrong3Option.style.backgroundColor = "#d94a69";

                            setTimeout(() => {
                              correctOption.style.backgroundColor = "#4AD99F";
                            }, 187);
                            setTimeout(() => {
                              correctOption.style.transform = "scale(0)";
                              wrong1Option.style.transform = "scale(0)";
                              wrong2Option.style.transform = "scale(0)";
                              wrong3Option.style.transform = "scale(0)";
                              // Check if quiz should be over
                              this.state.onQuestion + 1 >
                              this.state.numOfQuestions
                                ? this.setState({
                                    display: "done",
                                    numCorrect: numberCorrectSoFar + 1
                                  })
                                : // Update state
                                  this.setState({
                                    onQuestion: currentOnQuestion + 1,
                                    numCorrect: numberCorrectSoFar + 1
                                  });
                            }, 1000);
                            setTimeout(() => {
                              correctOption.style.transform = "scale(1)";
                              wrong1Option.style.transform = "scale(1)";
                              wrong2Option.style.transform = "scale(1)";
                              wrong3Option.style.transform = "scale(1)";
                              // color
                              correctOption.style.backgroundColor = "#5397fd";
                              wrong1Option.style.backgroundColor = "#5397fd";
                              wrong2Option.style.backgroundColor = "#5397fd";
                              wrong3Option.style.backgroundColor = "#5397fd";

                              // Make page clickable again
                              document.getElementById(
                                "the-main-container-id"
                              ).style.pointerEvents = "auto";
                            }, 1330);
                          }}
                          id={`${opt.id}`}
                        >
                          {opt.text}
                        </div>
                        <br />
                      </React.Fragment>
                    );
                    let incorrectOption = (
                      <React.Fragment key={opt.id}>
                        <div
                          className="option-card quiz-option m-auto p-2"
                          style={{
                            borderRadius: "12px"
                          }}
                          onClick={() => {
                            // Make page unclickable
                            document.getElementById(
                              "the-main-container-id"
                            ).style.pointerEvents = "none";

                            let numberWrongSoFar = this.state.numIncorrect;
                            let currentOnQuestion = this.state.onQuestion;
                            let correctOption = document.getElementById(
                              "correct"
                            );
                            let wrong1Option = document.getElementById(
                              "option1"
                            );
                            let wrong2Option = document.getElementById(
                              "option2"
                            );
                            let wrong3Option = document.getElementById(
                              "option3"
                            );
                            correctOption.style.backgroundColor = "#4cf5b0";
                            correctOption.style.transform = "scale(1.17)";
                            wrong1Option.style.backgroundColor = "#d94a69";
                            wrong2Option.style.backgroundColor = "#d94a69";
                            wrong3Option.style.backgroundColor = "#d94a69";

                            setTimeout(() => {
                              correctOption.style.backgroundColor = "#4AD99F";
                            }, 187);
                            setTimeout(() => {
                              correctOption.style.transform = "scale(0)";
                              wrong1Option.style.transform = "scale(0)";
                              wrong2Option.style.transform = "scale(0)";
                              wrong3Option.style.transform = "scale(0)";
                              // Check if quiz should be over
                              this.state.onQuestion + 1 >
                              this.state.numOfQuestions
                                ? this.setState({
                                    display: "done",
                                    numIncorrect: numberWrongSoFar + 1
                                  })
                                : // Update state
                                  this.setState({
                                    onQuestion: currentOnQuestion + 1,
                                    numIncorrect: numberWrongSoFar + 1
                                  });
                            }, 3000);
                            setTimeout(() => {
                              correctOption.style.transform = "scale(1)";
                              wrong1Option.style.transform = "scale(1)";
                              wrong2Option.style.transform = "scale(1)";
                              wrong3Option.style.transform = "scale(1)";
                              // color
                              correctOption.style.backgroundColor = "#5397fd";
                              wrong1Option.style.backgroundColor = "#5397fd";
                              wrong2Option.style.backgroundColor = "#5397fd";
                              wrong3Option.style.backgroundColor = "#5397fd";
                              // Make page clickable again
                              document.getElementById(
                                "the-main-container-id"
                              ).style.pointerEvents = "auto";
                            }, 3330);
                          }}
                          id={`${opt.id}`}
                        >
                          {opt.text}
                        </div>
                        <br />
                      </React.Fragment>
                    );
                    return opt.id === "correct"
                      ? correctOption
                      : incorrectOption;
                  })
                : ""}
              {/* <button
                onClick={() => {
                  let currentQuestionNumber = this.state.onQuestion;
                  this.setState({ onQuestion: currentQuestionNumber + 1 });
                }}
              >
                +
              </button>
              <button
                onClick={() => {
                  console.log(this.state);
                }}
              >
                state
              </button> */}
              <div style={{ height: "127px" }}></div>
            </div>
          </div>
          <div
            className=""
            style={{
              position: "fixed",
              bottom: "0px",
              width: "100%",
              maxWidth: "1000px",
              backgroundColor: "white",
              borderRadius: "27px 27px 0 0"
            }}
          >
            <div
              className="m-auto p-3"
              style={{ maxWidth: "370px", width: "80%" }}
            >
              <p className="light text-center">{`Question ${this.state.onQuestion} of ${this.state.numOfQuestions}`}</p>
              <div
                style={{
                  backgroundColor: "lightgray",
                  borderRadius: "27px"
                }}
              >
                <p
                  className="text-center progress-bar"
                  style={{
                    height: "10px",
                    width: `${parseFloat(
                      this.state.onQuestion / this.state.numOfQuestions
                    ) * 100}%`,
                    borderRadius: "27px",
                    backgroundImage:
                      "linear-gradient(270deg, #D491FA 0%, #81ADFD 100%)"
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      );

      // Set display
      displayContent = menu;

      if (this.state.display === "loading") {
        displayContent = <Spinner />;
      }
      if (this.state.display === "done") {
        displayContent = done;
      }
      if (this.state.display === "quiz") {
        displayContent = quiz;
      }
    }

    return (
      <div
        className="container-fluid p-0"
        id="the-main-container-id"
        style={{ backgroundColor: "", minHeight: "100vh" }}
      >
        {displayContent}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TherapeuticClass);
