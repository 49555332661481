//// SUBSCRIBE yearly
export const subscribeYearly = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    var user = firebase.auth().currentUser;

    const profile = firestore.collection("users").doc(user.uid);

    profile
      .update({
        "subscription.active": true,
        "subscription.type": "year",
        "subscription.subscribedDate": firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        dispatch({ type: "YEAR_SUBSCRIBE" });
      })
      .catch(err => console.log(err));
  };
};
//// SUBSCRIBE forver
export const subscribeForever = () => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    var user = firebase.auth().currentUser;

    const profile = firestore.collection("users").doc(user.uid);

    profile
      .update({
        "subscription.active": true,
        "subscription.type": "forever",
        "subscription.subscribedDate": firebase.firestore.FieldValue.serverTimestamp(),
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        dispatch({ type: "FOREVER_SUBSCRIBE" });
      })
      .catch(err => console.log(err));
  };
};

//// CHANGE NAME
export const changeName = name => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    var user = firebase.auth().currentUser;

    const profile = firestore.collection("users").doc(user.uid);

    profile
      .update({
        name: name,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        dispatch({ type: "CHANGE_NAME" });
      })
      .catch(err => console.log(err));
  };
};
//// CHANGE Occupation
export const changeOccupation = occupation => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    var user = firebase.auth().currentUser;

    const profile = firestore.collection("users").doc(user.uid);

    profile
      .update({
        "userInfo.occupation": occupation,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        dispatch({ type: "CHANGE_OCCUPATION" });
      })
      .catch(err => console.log(err));
  };
};
//// UPDATE Demographics
export const updateDemographics = demographics => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    var user = firebase.auth().currentUser;

    const profile = firestore.collection("users").doc(user.uid);

    profile
      .update({
        userInfo: demographics,
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
      })
      .then(() => {
        dispatch({ type: "UPDATE_DEMOGRAPHICS" });
      })
      .catch(err => console.log(err));
  };
};
//// UPDATE FAVORITE
export const updateFavorite = (item, action) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    var user = firebase.auth().currentUser;

    const profile = firestore.collection("users").doc(user.uid);

    /////// ADD
    if (action === "add") {
      if (item.type === "drug") {
        profile.update({
          "favorites.drugs": firebase.firestore.FieldValue.arrayUnion(
            `${item.id}`
          ),
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        });
      } else if (item.type === "pyrl") {
        profile.update({
          "favorites.pyrls": firebase.firestore.FieldValue.arrayUnion(
            `${item.id}`
          ),
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        });
      } else if (item.type === "doc") {
        profile.update({
          "favorites.docs": firebase.firestore.FieldValue.arrayUnion(
            `${item.id}`
          ),
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        });
      }
      //////// REMOVE
    } else if (action === "remove") {
      if (item.type === "drug") {
        profile.update({
          "favorites.drugs": firebase.firestore.FieldValue.arrayRemove(
            `${item.id}`
          ),
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        });
      } else if (item.type === "pyrl") {
        profile.update({
          "favorites.pyrls": firebase.firestore.FieldValue.arrayRemove(
            `${item.id}`
          ),
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        });
      } else if (item.type === "doc") {
        profile.update({
          "favorites.docs": firebase.firestore.FieldValue.arrayRemove(
            `${item.id}`
          ),
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
        });
      }
    }
  };
};
