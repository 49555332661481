import React from "react";
import spinner from "../../img/spinners/spinner.gif";

export default () => {
  return (
    <div>
      <div className="main-loading-spinner-container">
        <img
          src={spinner}
          alt="Loading..."
          style={{ height: "57px", opacity: "0.87" }}
        />
      </div>
    </div>
  );
};
