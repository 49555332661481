import React, { Component } from "react";
import { connect } from "react-redux";
import { resetPassword } from "../../store/actions/authActions";
import { Link } from "react-router-dom";

import logo from "../../img/logos/mm-txt.svg";

class PasswordReset extends Component {
  state = {
    email: ""
  };

  formType = e => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };
  onSubmit = e => {
    e.preventDefault();
    this.props.resetPassword(this.state.email);
  };

  render() {
    console.log(this.props);
    return (
      <div className="container-fluid p-0 auth-background">
        <div className="row no-gutters">
          <div className="col-lg-3 m-auto p-2">
            <div className="text-center mt-5 mb-5"></div>
            <div
              className="auth-container mb-5"
              style={{ borderRadius: "27px" }}
            >
              <div className="text-center pt-3 pb-3">
                <a href="https://medmorize.co">
                  <img src={logo} alt="Medmorize logo" className="auth-logo" />
                </a>
                <h3 className="text-bluegray light mb-3 mt-5">
                  Password Reset
                </h3>
              </div>
              <div className="p-2 text-center">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control input-3"
                      name="email"
                      placeholder="Email Address"
                      onChange={this.formType}
                    />
                  </div>
                  <br />
                  <button
                    type="submit"
                    className="content-container-5 submit-btn-1"
                  >
                    Submit
                  </button>
                  <hr />
                  <Link to="/login">
                    <button className="modal-link-btn">
                      <p className="m-1 mr-3 ml-3 text-white">Login</p>
                    </button>
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: email => dispatch(resetPassword(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);

/* 


*/
