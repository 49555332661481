import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// Actions
import { logOut } from "../../store/actions/authActions";
// Components
import Spinner from "../common/Spinner";
import LandingNav from "../navs/LandingNav";
// images
import waves from "../../img/figures/wave.svg";

// #c35fff

class Landing extends Component {
  state = {
    quiz: 1
  };
  render() {
    const { profile } = this.props;

    let displayContent;

    if (profile.name === undefined) {
      displayContent = <Spinner />;
    } else {
      if (profile.subscription.active === false) {
        // displayContent = <Subscribe />;
      } else {
        displayContent = (
          <div
            className="container p-0"
            style={{
              maxWidth: "1000px",
              minHeight: "100vh"
            }}
          >
            <div className="row no-gutters">
              <p>hello</p>
              <button onClick={this.props.logOut}>logout</button>
            </div>
          </div>
        );
      }
    }

    let displayQuiz;

    let quiz1 = (
      <div>
        <h5 className="light">Select the brand name for</h5>
        <h4 className="boldest mb-3">Losartan</h4>
        <div className="text-center">
          <div
            className="option-card quiz-option p-2 m-auto"
            id="option1"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";

              let numberWrongSoFar = this.state.numIncorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numIncorrect: numberWrongSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 2
                    });
              }, 3000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";
                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 3330);
            }}
          >
            Lipitor
          </div>
          <br />
          <div
            className="option-card quiz-option p-2 m-auto"
            id="correct"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";
              let numberCorrectSoFar = this.state.numCorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numCorrect: numberCorrectSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 2
                    });
              }, 1000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";

                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 1330);
            }}
          >
            Cozaar
          </div>
          <br />
          <div
            className="option-card quiz-option p-2 m-auto"
            id="option2"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";

              let numberWrongSoFar = this.state.numIncorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numIncorrect: numberWrongSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 2
                    });
              }, 3000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";
                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 3330);
            }}
          >
            Diovan
          </div>
          <br />
          <div
            className="option-card quiz-option p-2 m-auto"
            id="option3"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";

              let numberWrongSoFar = this.state.numIncorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numIncorrect: numberWrongSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 2
                    });
              }, 3000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";
                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 3330);
            }}
          >
            Altace
          </div>
          <br />
        </div>
      </div>
    );
    let quiz2 = (
      <div>
        <h5 className="light">Select the drug class for</h5>
        <h4 className="boldest mb-3">Gemfibrozil</h4>
        <div className="text-center">
          <div
            className="option-card quiz-option p-2 m-auto"
            // style={{ borderRadius: "17px", width: "250px" }}
            id="option1"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";

              let numberWrongSoFar = this.state.numIncorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numIncorrect: numberWrongSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 3
                    });
              }, 3000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";
                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 3330);
            }}
          >
            Bile Acid Resin
          </div>
          <br />
          <div
            className="option-card quiz-option p-2 m-auto"
            // style={{ borderRadius: "17px", width: "250px" }}
            id="option2"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";

              let numberWrongSoFar = this.state.numIncorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numIncorrect: numberWrongSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 3
                    });
              }, 3000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";
                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 3330);
            }}
          >
            Statin
          </div>
          <br />
          <div
            className="option-card quiz-option p-2 m-auto"
            // style={{ borderRadius: "17px", width: "250px" }}
            id="correct"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";
              let numberCorrectSoFar = this.state.numCorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numCorrect: numberCorrectSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 3
                    });
              }, 1000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";

                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 1330);
            }}
          >
            Fibrate
          </div>
          <br />
          <div
            className="option-card quiz-option p-2 m-auto"
            // style={{ borderRadius: "17px", width: "250px" }}
            id="option3"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";

              let numberWrongSoFar = this.state.numIncorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numIncorrect: numberWrongSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 3
                    });
              }, 3000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";
                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 3330);
            }}
          >
            PCSK9 Inhibitor
          </div>
          <br />
        </div>
      </div>
    );
    let quiz3 = (
      <div>
        <h5 className="light">Select the mechanism of action for</h5>
        <h4 className="boldest mb-3">Duloxetine</h4>
        <div className="text-center">
          <div
            className="option-card quiz-option p-2 m-auto"
            style={{ borderRadius: "17px", width: "250px", fontSize: "11pt" }}
            id="option1"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";

              let numberWrongSoFar = this.state.numIncorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numIncorrect: numberWrongSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 1
                    });
              }, 3000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";
                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 3330);
            }}
          >
            Inhibits serotonin reuptake in the neuronal synapse.
          </div>
          <br />
          <div
            className="option-card quiz-option p-2 m-auto"
            style={{ borderRadius: "17px", width: "250px", fontSize: "11pt" }}
            id="option2"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";

              let numberWrongSoFar = this.state.numIncorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numIncorrect: numberWrongSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 1
                    });
              }, 3000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";
                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 3330);
            }}
          >
            Inhibits serotonin reuptake in the neuronal synapse, and partially
            agonizes 5-HT1A receptors.
          </div>
          <br />
          <div
            className="option-card quiz-option p-2 m-auto"
            style={{ borderRadius: "17px", width: "250px", fontSize: "11pt" }}
            id="correct"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";
              let numberCorrectSoFar = this.state.numCorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numCorrect: numberCorrectSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 1
                    });
              }, 1000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";

                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 1330);
            }}
          >
            Inhibits serotonin & norepinephrine reuptake in the neuronal
            synapse.
          </div>
          <br />
          <div
            className="option-card quiz-option p-2 m-auto"
            style={{ borderRadius: "17px", width: "250px", fontSize: "11pt" }}
            id="option3"
            onClick={() => {
              // Make page unclickable
              document.getElementById(
                "the-main-container-id"
              ).style.pointerEvents = "none";

              let numberWrongSoFar = this.state.numIncorrect;
              let currentOnQuestion = this.state.onQuestion;
              let correctOption = document.getElementById("correct");
              let wrong1Option = document.getElementById("option1");
              let wrong2Option = document.getElementById("option2");
              let wrong3Option = document.getElementById("option3");
              correctOption.style.backgroundColor = "#4cf5b0";
              correctOption.style.transform = "scale(1.17)";
              wrong1Option.style.backgroundColor = "#d94a69";
              wrong2Option.style.backgroundColor = "#d94a69";
              wrong3Option.style.backgroundColor = "#d94a69";

              setTimeout(() => {
                correctOption.style.backgroundColor = "#4AD99F";
              }, 187);
              setTimeout(() => {
                correctOption.style.transform = "scale(0)";
                wrong1Option.style.transform = "scale(0)";
                wrong2Option.style.transform = "scale(0)";
                wrong3Option.style.transform = "scale(0)";
                // Check if quiz should be over
                this.state.onQuestion + 1 > this.state.numOfQuestions
                  ? this.setState({
                      display: "done",
                      numIncorrect: numberWrongSoFar + 1
                    })
                  : // Update state
                    this.setState({
                      quiz: 1
                    });
              }, 3000);
              setTimeout(() => {
                correctOption.style.transform = "scale(1)";
                wrong1Option.style.transform = "scale(1)";
                wrong2Option.style.transform = "scale(1)";
                wrong3Option.style.transform = "scale(1)";
                // color
                correctOption.style.backgroundColor = "#5397fd";
                wrong1Option.style.backgroundColor = "#5397fd";
                wrong2Option.style.backgroundColor = "#5397fd";
                wrong3Option.style.backgroundColor = "#5397fd";
                // Make page clickable again
                document.getElementById(
                  "the-main-container-id"
                ).style.pointerEvents = "auto";
              }, 3330);
            }}
          >
            Inhibits norepinephrine and serotonin reuptake in the CNS. Also
            notably produces side effects as a result of affinity for inhibiting
            histamine and alpha-adrenergic receptors.
          </div>
          <br />
        </div>
      </div>
    );

    if (this.state.quiz === 1) {
      displayQuiz = quiz1;
    }
    if (this.state.quiz === 2) {
      displayQuiz = quiz2;
    }
    if (this.state.quiz === 3) {
      displayQuiz = quiz3;
    }

    return (
      <div
        className="container-fluid p-0"
        style={{ backgroundColor: "", minHeight: "100vh" }}
      >
        <div
          className="container p-0"
          style={{
            maxWidth: "1000px",
            minHeight: "50vh"
          }}
        >
          <div className="row no-gutters mb-1">
            <div className="col-md-12">
              <LandingNav />
            </div>
          </div>
          <div className="row no-gutters mb-1" style={{ height: "7vh" }}>
            <div className="col-md-12"></div>
          </div>
          <div className="row no-gutters">
            <div className="col-md-6 pl-4 pr-1">
              <h2 className="boldest text-bluegray mb-4">Unlimited Quizzing</h2>
              <div className="pl-4 pr-5 drug-title-container">
                <h4 className="boldest text-bluegray">250+ Top Drugs</h4>
                <h5 className="bold text-bluegray">Brand-Generic</h5>
                <h5 className="bold text-bluegray">Therapeutic Class</h5>
                <h5 className="bold text-bluegray">Mechanism of Action</h5>
                <br />
                <Link to="/signup">
                  <button className="btn grad-btn mb-4 bold">
                    Get Started
                  </button>
                </Link>
                {/* <div>
                  <div className="row no-gutters mt-3 mb-5">
                    <div className="col-md-4 text-center">
                      <h1 className="boldest text-bluegray">
                        $12 <span style={{ fontSize: "18px" }}> / year</span>
                      </h1>
                    </div>
                    <div className="col-md-6 text-center">
                      <h1 className="boldest text-bluegray">
                        $25 <span style={{ fontSize: "18px" }}> / forever</span>
                      </h1>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-6 pr-3 pl-3 mb-3">
              <div
                id="the-main-container-id"
                className="auth-container text-center pt-3 m-auto"
                style={{ borderRadius: "17px", maxWidth: "350px" }}
              >
                {displayQuiz}
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters p-0">
          <div className="col-md-12 p-0">
            <img src={waves} alt="" className="waves" />
          </div>
        </div>
        <div
          className="row no-gutters p-0"
          style={{
            minHeight: "20vh",
            backgroundColor: "#AECBFB"
          }}
        >
          <div className="col-md-6 m-auto pb-4">
            <div className="row no-gutters">
              <div
                className="col-md-3 m-auto text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <a
                  href="https://rxg777.typeform.com/to/Fplccg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="mb-0 text-gray-light bold opa-hover">Contact</p>
                </a>
              </div>
              <div
                className="col-md-3 m-auto text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Link to="/disclaimer">
                  <p className="mb-0 text-gray-light bold opa-hover">
                    Disclaimer
                  </p>
                </Link>
              </div>
              <div
                className="col-md-3 m-auto text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Link to="/terms-of-service">
                  <p className="mb-0 text-gray-light bold opa-hover">
                    Terms of Service
                  </p>
                </Link>
              </div>
              <div
                className="col-md-3 m-auto text-center"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Link to="/privacy-policy">
                  <p className="mb-0 text-gray-light bold opa-hover">
                    Privacy Policy
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// AECBFB

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOut: () => dispatch(logOut())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
