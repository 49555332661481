const initState = {};

const profileReducer = (state = initState, action) => {
  switch (action.type) {
    case "YEAR_SUBSCRIBE":
      // window.location.reload();
      return {
        ...state
        // authError: action.err.message
      };
    case "FOREVER_SUBSCRIBE":
      // window.location.reload();
      return {
        ...state
        // authError: action.err.message
      };
    case "CHANGE_NAME":
      window.location.reload();
      return {
        ...state
        // authError: action.err.message
      };
    case "CHANGE_OCCUPATION":
      window.location.reload();
      return {
        ...state
        // authError: action.err.message
      };
    case "UPDATE_DEMOGRAPHICS":
      window.location.reload();
      return {
        ...state
        // authError: action.err.message
      };
    default:
      return state;
  }
};

export default profileReducer;
